import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';
import { getAuth, createUserWithEmailAndPassword, updateProfile, GoogleAuthProvider, signInWithPopup, sendEmailVerification } from "firebase/auth";
import { UserService } from 'src/app/services/user.service';
import { serverTimestamp } from 'firebase/firestore'
import { CreationService } from 'src/app/services/creation.service';
import { fakeAsync } from '@angular/core/testing';
import { Title, Meta } from '@angular/platform-browser';
import anime from 'animejs/lib/anime.es';


@Component({
  selector: 'app-request-access',
  templateUrl: './request-access.component.html',
  styleUrl: './request-access.component.scss'
})
export class RequestAccessComponent {

  userForm: UntypedFormGroup;
  errorMsg: string;
  isProcessing: boolean = false;
  isSubmitted: boolean = false;
  submittedDateKey = 'access-request-submitted-date';

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private creationService: CreationService,
    private titleService: Title,
    private metaService: Meta,
    private el: ElementRef,
  ) {
    this.titleService.setTitle('Song Identity | Request Access');
    this.metaService.updateTag(
      {
        name: 'description',
        content: 'Request Access to the Beta.'
      }
    );
  };

  errorMsgs = {
    name: [
      {
        type: 'required',
        message: 'Enter your name...'
      },
    ],
    email: [
      {
        type: 'required',
        message: 'Enter an email...'
      },
      {
        type: 'pattern',
        message: 'Email is not valid.'
      }
    ],
  };

  ngOnInit() {
    this.checkSubmittedDate();
    this.userForm = this.fb.group({
      name: new UntypedFormControl('', Validators.compose([
        Validators.required
      ])),
      email: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      organization: new UntypedFormControl('', Validators.compose([
        Validators.required,
      ])),
    });
  };

  onFieldIn() {
    this.errorMsg = '';
  };

  onClickSubmit(name, email, org) {
    this.isProcessing = true;

    setTimeout(() => {
      const info = {
        name,
        email,
        organization: org || 'Not Provided', 
      }
      this.userService.createUserAccessRequest(info).then((data) => {
        if (data != null) {
          this.isProcessing = false;
          const currentDate = new Date();
          localStorage.setItem(this.submittedDateKey, currentDate.toISOString());
          this.isSubmitted = true;
        }
      }).catch((e) => {
        console.log('there was an error with the request', e);
      })
    }, 2000);
  };

  checkSubmittedDate() {
    const submittedDateString = localStorage.getItem(this.submittedDateKey);
    if (submittedDateString) {
      const submittedDate = new Date(submittedDateString);
      const twoDaysAgo = new Date();
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

      this.isSubmitted = submittedDate > twoDaysAgo;
    }
  }

}
