import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';

@Pipe({
    name: 'timeAgoPipe',
})
export class TimeAgoPipe implements PipeTransform {
    transform(value: any): string {
        if (!value) {
            return '';
        }

        const jsDate = value.toDate(); // Convert Firestore Timestamp to JavaScript Date
        return formatDistanceToNow(jsDate);
    }
}