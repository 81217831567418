<div class="fs-animation-wrapper">
    <div class="animation-content">
        <div #animation [ngStyle]="wrapperStyle" style="scale: 0.8;">
            <div id="circles" [ngStyle]="circlesStyle">

                <div class="circle left block outer" [ngStyle]="circleStyle"></div>
                <div class="circle block" [ngStyle]="primaryCircleStyle">

                    <div class="middle" [ngStyle]="middleStyle">
                        <svg [ngStyle]={svgStyle} class="logo-el" width="50px" height="48px" viewBox="0 0 25 23"
                            version="1.1">
                            <g id="Page-1" stroke="none" strokeWidth="1.8" fill="none" fillRule="evenodd">
                                <g id="Artboard" transform="translate(-179.000000, -147.000000)" stroke="#9069ff"
                                    strokeWidth="1.8">
                                    <line x1="193.141958" y1="150.307199" x2="204" y2="150.307199" id="Path-29" />
                                    <circle id="Oval" cx="191.5" cy="150.5" r="2.5" />
                                    <circle id="Oval-Copy-2" cx="191.5" cy="159.5" r="2.5" />
                                    <circle id="Oval-Copy" cx="182.5" cy="150.5" r="2.5" />
                                    <circle id="Oval-Copy-3" cx="182.5" cy="159.5" r="2.5" />
                                    <path
                                        d="M194.32863,159.126398 L195.946241,159.126398 C197.050811,159.126398 197.946241,160.021828 197.946241,161.126398 L197.946241,170 L197.946241,170"
                                        id="Path-30" strokeLinejoin="round" />
                                </g>
                            </g>
                        </svg>
                    </div>

                </div>

                <div class="circle block right outer" [ngStyle]="circleStyle"></div>

            </div>
        </div>
        <div class="logo-wrapper">SONG IDENTITY</div>
    </div>
</div>