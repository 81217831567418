import { Injectable } from '@angular/core';
import { SubMonitorService } from './sub-monitor.service';
import { getAuth } from 'firebase/auth';
import { UserService } from './user.service';
import { MicroStateService } from './micro-state.service';

@Injectable({
  providedIn: 'root'
})
export class SubCheckService {

  constructor(
    private subMonitor: SubMonitorService,
    private userService: UserService,
    private microState: MicroStateService,
  ) { }

  async checkSubscription() {
    const auth = await getAuth();
    auth.authStateReady().then(() => {
      setTimeout(() => {
        if (auth.currentUser) {
          this.checkUser(auth.currentUser);
        }
      }, 1800)
    });
  }

  checkUser(user) {
    if (user) {
      this.userService.getUserPublicProfileById(user.uid)
        .then((profileData) => {
          if (profileData != null) {

            user.getIdToken(true);
            user.getIdTokenResult().then((data) => {
              const subscription = data.claims.stripeRole;
              switch (subscription) {
                case undefined:
                  this.subMonitor.setSubscription("trial");
                  this.checkTrial(profileData);
                  break;
                case null:
                  this.subMonitor.setSubscription("trial");
                  this.checkTrial(profileData);
                  break;
                case "grandFather":
                  this.subMonitor.setSubscription('grandFather');
                  localStorage.setItem('chrome-ext-id', user.uid);
                  break;
                case "beta":
                  this.subMonitor.setSubscription('beta');
                  localStorage.setItem('chrome-ext-id', user.uid);
                  break;
                case "subMonthly":
                  this.subMonitor.setSubscription('subMonthly');
                  localStorage.setItem('chrome-ext-id', user.uid);
                  break;
                case "subYearly":
                  this.subMonitor.setSubscription('subYearly');
                  localStorage.setItem('chrome-ext-id', user.uid);
                  break;
              }
            });

          }

          else {
            console.log('no user on token change')
          }

        });
    }
  }

  checkTrial(profile) {
    const signUpDate = new Date(profile.createdAt.seconds * 1000 + profile.createdAt.nanoseconds / 1000000);

    const daysAgo = new Date();
    daysAgo.setDate(daysAgo.getDate() - 7);

    if (signUpDate > daysAgo) {
      const remainingTimeMs = signUpDate.getTime() - daysAgo.getTime();
      const remainingDays = Math.floor(remainingTimeMs / (1000 * 60 * 60 * 24));
      const remainingHours = Math.floor((remainingTimeMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  
      const remainingTimeString = `${remainingDays} days and ${remainingHours} hours`;
  
      console.log(`Trial expires in ${remainingTimeString}`);
      this.microState.setData('trialDaysRemaining', remainingDays);
      this.microState.setData('trialHoursRemaining', remainingHours);

      localStorage.setItem('chrome-ext-id', profile.id);
    } else {
      localStorage.removeItem('chrome-ext-id');
      this.subMonitor.setSubscription("expired");
    }
  }
}
