import { Injectable } from '@angular/core';
import { MLC_URLS } from '../constants/api-urls';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MlcServiceService {

  constructor(private http: HttpClient) {}

  getDataFromMLC(isrc: string, iswc: string): Observable<any> {
    if (isrc) {
      const requestBody = {
        isrc,
        iswc,
      };

      return this.http.post<any>(MLC_URLS.getDataFromMLC, requestBody);
    } else {
      throw new Error('Invalid Data URL');
    }
  };

}
