import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-big-node',
  templateUrl: './big-node.component.html',
  styleUrl: './big-node.component.scss'
})
export class BigNodeComponent {

  constructor(private router: Router) {
    this.router.navigate(['/login']);
  }
}
