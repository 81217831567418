
<div class="ss-auth-layout">
  <div class="brand-text" style="cursor: default;">SONG</div>

  <div class="ss-auth-presentation">
   
    <div class="info-card">
      <div class="title-wrapper">
        <div class="word-wrapper">
          <div id="sw1" class="word-swiper one"><div class="swipe-text">Who</div></div>
          <div class="word">Discover</div>
        </div>
        <div class="word-wrapper">
          <div id="sw2" class="word-swiper two"><div class="swipe-text">Owns This</div></div>
          <div class="word">Ownership</div>
        </div>
        <div class="word-wrapper">
          <div id="sw3" class="word-swiper three"><div class="swipe-text">Song</div></div>
          <div class="word">Data</div>
        </div>
      </div>

      <div class="message-wrapper">
        <div class="message">
          Song Identity allows you to search for copyright and ownership information in a friendly way.
        </div>
        <div class="message">
          We are working to incorporate verifiable data through Switchchord’s identity system.
        </div>
      </div>
    </div>

    <div class="auth-area">

      <div id="card" *ngIf="!isProcessingProvider" class="ss-auth-content ss-modal-card">
        <div id="header" class="ss-auth-header mb24">
          Login
        </div>
        <div class="ss-auth-form">
          <form class="form" [formGroup]="userForm" (ngSubmit)="signIn(userForm.value.email, userForm.value.password)">
    
            <div class="ss-form-group">
              <input id="email" class="ss-input expanded " type="email" autocomplete="off" formControlName="email"
                placeholder="you@something.com" (focus)="onFieldIn()" autocomplete="username" [ngClass]="{ 'idle' : isPreparing }" />
            </div>
    
            <div class="ss-form-group">
              <input id="password" class="ss-input expanded " type="password" formControlName="password"
                placeholder="Your password..." autocomplete="off" (focus)="onFieldIn()" autocomplete="current-password"
                required [ngClass]="{ 'idle' : isPreparing }" />
            </div>
    
            <app-message-prompt *ngIf="errorMsg" type="danger auth" mb="24px" messageTitle="Login Failed"
              messageBody="{{ errorMsg }}">
            </app-message-prompt>
    
            <button id="login-button" class="ss-primary-btn" [disabled]="!userForm.valid || isAuthenticating" [ngClass]="{ 'idle' : isPreparing }">
              Continue
            </button>
    
            <!-- <hr class="mt24 mb24" /> -->
    
            <!-- <div id="o-auth" class="ss-secondary-btn o-auth" (click)="signInGoogle()" [ngClass]="{ 'idle' : isPreparing }">
              <svg-icon src="/assets/icons/google.svg"></svg-icon>
              Continue with Google
            </div>
    
    
            <div class="ss-terms-prompt-wrapper">
              By continuing you agree to these
              <a routerLink="/terms">Terms</a> and
              <a routerLink="/privacy">Privacy Policy</a>
            </div> -->
    
            <div class="ss-auth-form-sub-row">
              <div class="auth-link" routerLink="/reset">
                Forgot Password
              </div>
            </div>

            <div class="ss-auth-form-sign-up-row">
              Don't have an account?&nbsp;
              <span class="sign-up-link" routerLink="/sign-up">
                Get Started
              </span>
            </div>
    
          </form>
        </div>
      </div>

    </div>

  </div>

  <div class="brand-tag">
    <div class="logo">
        <div class="text">Powered by</div>
        <svg-icon [svgStyle]="{ 'height.px':13 }" src="/assets/logos/sc_logo.svg"></svg-icon>
       
    </div>
</div>

</div>