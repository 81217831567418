import { Component, ElementRef } from '@angular/core';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { MicroStateService } from 'src/app/services/micro-state.service';

import anime from 'animejs/lib/anime.es';
import { UserListsService } from 'src/app/services/user-lists.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SubMonitorService } from 'src/app/services/sub-monitor.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-saved-songs-list',
  templateUrl: './saved-songs-list.component.html',
  styleUrl: './saved-songs-list.component.scss'
})
export class SavedSongsListComponent {
  user: any;
  listId: any;
  listSongs: any;
  listData: any;
  isRemovingFromList: boolean = false;
  isDeletingList: boolean = false;
  isLoading: boolean = true;
  showDeleteList: boolean = false;

  searchQuery: string = '';
  private searchQuerySubject = new Subject<string>();
  searchActive: boolean = false;

  constructor(
    private microState: MicroStateService,
    private el: ElementRef,
    private listService: UserListsService,
    private router: Router,
    private route: ActivatedRoute,
    private subMonitor: SubMonitorService,
    private titleService: Title,
  ) {
    this.titleService.setTitle('Song Identity - List');
    this.searchQuerySubject.pipe(debounceTime(200)).subscribe(() => {
      this.onInputChange();
    });
  }

  ngOnInit() {
    this.loadUserData();
    this.route.paramMap.subscribe(params => {
       this.listId = params.get('listId');
    });

    this.subMonitor.getSubscription().subscribe((data) => {
      if (data === 'expired') { 
        this.router.navigate(['activate-subscription'])
       };
    });
  };

  async loadUserData() {
    const auth = await this.getAuthState();
    if (auth.currentUser) {
      this.user = auth.currentUser;
      let loaded = false;
      this.microState.stateData$.subscribe((data) => {
        if (data != null && !loaded) {
          loaded = true;
          this.listData = data.selectedListData || null;
          this.handleLoadListData();
        }
      });
    }
  };

  async getAuthState() {
    return getAuth();
  };

  handleLoadListData() {
    if (this.listData == null) {
      this.listService.getListDataByListId(this.user.uid, this.listId).then((data) => {
        if (data != null) {
          this.listData = data;
        }
      })
    }
    this.listService.getAllSongsFromUserList(this.user.uid, this.listId).subscribe((data) => {
      if (data != null) {
        this.listSongs = data.sort((a ,b) => b.savedAt - a.savedAt);
        if (data.length === 0) {
          this.isLoading = false;
          this.handleAnimateEmptyState();
        } else {
          this.isLoading = false;
          this.handleAnimateLists();
        }
      } else {
        console.log('Error getting user lists'); // TODO: Add friendly error
      }
    })
  }

  onClickSong(song) {
    this.microState.setData('discoveredTrackData', song);
    this.router.navigate(['list', this.listId, song.trackSourceId])
  }

  onClickRemoveSong(song) {
    this.isRemovingFromList = true;
    this.listService.removeSongFromUserList(this.user.uid, this.listId, song).then(() => {
      this.isRemovingFromList = false;
      this.handleLoadListData();
    }).catch((e) => {
      console.log('error when deleting from list', e); // TODO: ADD Friendly error
    })
  }

  onClickDeleteList() {
   this.showDeleteList = true;
  }

  handleDeleteList() {
    this.showDeleteList = false;
    this.isDeletingList = true;
    this.listService.deleteUserList(this.user.uid, this.listId).then(() => {
      this.isRemovingFromList = false;
      window.history.back();
    }).catch((e) => {
      console.log('error when deleting from list', e); // TODO: ADD Friendly error
    })
  }

  handleCancelDeleteList() {
    this.showDeleteList = false;
  }

  onClickBackToList() {
    window.history.back();
  }

  onInputChange() {
    if (this.searchQuery.length > 0) {
      this.searchActive = true;
    }
  }

  triggerSearchQuery() {
    this.searchQuerySubject.next(this.searchQuery);
  }

  handleClearSearch() {
    this.searchQuery = '';
  }
 

  // ANIMATIONS

  handleAnimateEmptyState() {
    const emptyStateAnimation = anime.timeline({ autoplay: false, loop: false });

    setTimeout(() => {
   
      emptyStateAnimation
        .add({
          targets: '.lists-empty-state',
          scale: [1.05, 1],
          opacity: [0, 1],
          translateY: [-40, 0],
          easing: 'easeOutCubic',
          duration: 900,
        })
        .add({
          targets: '.lists-empty-state svg',
          scale: [1.05, 1],
          opacity: [0, 1],
          translateY: [-5, 0],
          duration: 600,
        }, 200)
        .add({
          targets: '.lists-empty-state .message',
          scale: [1.05, 1],
          opacity: [0, 1],
          translateY: [12, 0],
          easing: 'easeOutCubic',
          duration: 600,
        }, 400);
      emptyStateAnimation.play();
    }, 100);
  }

  handleAnimateLists() {
    const listAnimation = anime.timeline({ autoplay: false, loop: false });

    setTimeout(() => {
      listAnimation
        .add({
          targets: '.info',
          opacity: [0, 1],
          translateY: [4, 0],
          easing: 'cubicBezier(.74, .11, .5, 1.26)',
          duration: 700,
        })
        .add({
          targets: '.list-search-wrapper',
          opacity: [0, 1],
          translateY: [4, 0],
          easing: 'cubicBezier(.74, .11, .5, 1.26)',
          duration: 600,
        }, 100)
        .add({
          targets: '.info .icon',
          opacity: [0, 1],
          scale: [1.4, 1],
          easing: 'cubicBezier(.74, .11, .5, 1.26)',
          duration: 300,
        }, 300)
        .add({
          targets: '.list-item',
          opacity: [0, 1],
          translateY: [4, 0],
          easing: 'easeOutExpo',
          duration: 600,
          delay: anime.stagger(100), // Stagger the animations by 100 milliseconds
        }, 400)
      listAnimation.play();
    }, 100);
  }

}
