<div class="overlay-wrapper">
    <div id="content" class="overlay-content">
        <div class="overlay-title">
            {{title}}
        </div>
        <div class="overlay-msg">
            {{msg}}
        </div>
        <div class="btn-wrapper">
           
            <button class="cancel-btn" (click)="onClickCancel()">{{cancelText}}</button>

            <button class="action-btn" (click)="onClickConfirm()">{{actionText}}</button>
        </div>
    </div>
</div>