import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { getAuth, onAuthStateChanged, onIdTokenChanged } from 'firebase/auth'
import { SubMonitorService } from './services/sub-monitor.service';
import { UserService } from './services/user.service';
import { logEvent, getAnalytics } from "firebase/analytics";
import { SubCheckService } from './services/sub-check.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Song Identity by Switchchord';
  showNav: boolean = true;
  newUserUX: boolean = false;
  subscription: any = null;

  constructor(
    private subCheckService: SubCheckService,
    private router: Router,
  ) {
    this.router.events.subscribe(event => {
      
      if (location.pathname.includes('login') || location.pathname.includes('sign-up')
      ) {
         this.showNav = false;
      } else {
        this.showNav = true;
      }

    });
  }

  ngOnInit() {
    this.subCheckService.checkSubscription();
    const isNewUser = localStorage.getItem('isNewUser');
    this.newUserUX = isNewUser === 'false' ? false : true;
    if (this.newUserUX) {
      localStorage.setItem('isNewUser', 'false');
    }
  }

  onAnimationComplete() {
    this.newUserUX = false;
  }

}
