
<page-loader *ngIf="isLoading"></page-loader>
<div #discoveryWrap id="backdrop" class="discovery-page-wrap" *ngIf="!isLoading">
    <div class="discovery-page-fade-panel" [ngClass]="{'reduced' : !searchResultData}"></div>

    <div class="utility-nav-bar" *ngIf="showUtilityBar">
        <div class="utility-content">
            <div id="utility-search" class="left">
                <div class="utility-search-wrapper">
                    <input id="secondary-search-input" autocomplete="off" class="ss-input expanded"
                    placeholder="Song Name or Spotify Link..." [(ngModel)]="searchString"
                    (keypress)="onSearchKeypress($event, {primary: false})" />
                    <div id="secondary-search-btn" class="search-bar-btn"
                    [ngClass]="{'active' : searchString && searchString.length > 1}" (click)="onClickDiscover({primary: false})">
                    <svg-icon [svgStyle]="{ 'height.px':12 }" src="/assets/icons/spyglass.svg"></svg-icon>
                </div>
                <div class="invalid-link-message" *ngIf="isInvalidLink">
                    <div class="link-icon"><svg-icon [svgStyle]="{ 'height.px':16 }"
                            src="/assets/icons/spotify-icon.svg"></svg-icon></div>
                    <div class="message-text">Spotify Track Links Only</div>
                </div>
                </div>
            </div>

            <div class="center" [ngClass]="{'active' : isLoadingSearchData}">
                <div class="search-spin-wrapper"><span *ngIf="isLoadingSearchData" class="search-spinner"></span></div>
                <div class="center-message">Loading Tracks</div>
            </div>

            <div id="utility-buttons"  class="right">
                <div class="icon-btn" (click)="onClickCancelBtn()">
                    <svg-icon [svgStyle]="{ 'height.px':28 }" src="/assets/icons/square-close.svg"></svg-icon>
                </div>
            </div>
        </div>
    </div>

    <div class="primary-search-wrapper" *ngIf="!searchResultData || (searchResultData && isLoadingSearchData && showPrimarySearch)">
        
        <div class="primary-search-field-wrap">
            <div class="search-header-wrapper">
                <div id="discover-header" class="search-header">
                    <span>Discover</span><span *ngIf="isLoadingSearchData">ing</span>
                </div>
            </div>
            <div id="discover-bar" class="search-bar-wrapper">
                <input id="primary-search-input" autocomplete="off" class="ss-input expanded mt16"
                    placeholder="Song Name or Spotify Link..." [(ngModel)]="searchString"
                    (keypress)="onSearchKeypress($event, {primary: true})" />
                <div id="search-btn" class="search-bar-btn"
                    [ngClass]="{'active' : searchString && searchString.length > 1}" (click)="onClickDiscover({primary: true})">
                    <div id="action-border" class="action-border" [ngStyle]="{'opacity': isLoadingSearchData ? 1 : 0}">
                    </div>
                    <svg-icon [svgStyle]="{ 'height.px':12 }" src="/assets/icons/spyglass.svg"></svg-icon>
                </div>

                <div class="invalid-link-message" *ngIf="isInvalidLink">
                    <div class="link-icon"><svg-icon [svgStyle]="{ 'height.px':16 }"
                            src="/assets/icons/spotify-icon.svg"></svg-icon></div>
                    <div class="message-text">Spotify Track Links Only</div>
                </div>

            </div>

            <div class="trial-prompt" *ngIf="trialDays != null && trialDays < 2" routerLink="/subscribe">
                <svg-icon [svgStyle]="{ 'height.px':16 }" src="/assets/icons/clock.svg" style="transform: translateY(1px); margin-right: 0.35rem"></svg-icon>
                {{trialDays}} days <div *ngIf="trialDays == 0">and {{trialHours}} hours</div> left in your free trial
            </div>

        </div>

    </div>

    <div id="spotify-results" class="spotify-results-grid">
        <div class="result-card" *ngFor="let s of searchResultData" (click)="onSelectTrack(s)">
            <div class="result-image-wrapper">
                <img *ngIf="s.albumArtURL" class="image" [src]="s.albumArtURL" />
            </div>
            <div class="result-name">{{s.title}}</div>
            <div class="result-artist">{{s.artist}}</div>
            <div class="result-isrc">ISRC: {{s.isrc}}</div>
        </div>
    </div>

    <div *ngIf="searchResultData && searchResultData.length > 0" class="spotify-results-bar">
        <div class="copy">
            <div class="link-icon"><svg-icon [svgStyle]="{ 'height.px':16 }"
                src="/assets/icons/spotify-icon.svg"></svg-icon></div>
        <div class="message-text">Results from Spotify</div>
        </div>
    </div>

</div>