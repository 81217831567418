<div class="ss-page-wrapper auth">
    <div class="ss-auth-content ss-modal-card">
        <div class="ss-auth-header">
           <span *ngIf="!emailSent">You Forgot</span>
           <span *ngIf="emailSent">Check your Email</span>
        </div>
        <div *ngIf="!emailSent" class="auth-sub-header">They say vitamin b-12 helps with your memory...</div>
        <div *ngIf="emailSent" class="auth-sub-header">You know the drill. If you have an account... check the email... click the button.</div>
        <div class="ss-auth-form">
            <form *ngIf="!emailSent" class="form" [formGroup]="resetForm" (ngSubmit)="onResetPassword()">

                <div class="ss-form-group">
                  <input class="ss-input expanded " type="email" autocomplete="off" formControlName="email"
                    placeholder="Enter Email..." [(ngModel)]="userSubmittedEmail"/>
                </div>
            
                <ng-container *ngFor="let error of errorMsgs.email">
                  <app-message-prompt *ngIf="resetForm.get('email').hasError(error.type) && resetForm.get('email').touched"
                    type="warning" mb="24px" messageTitle="Check your email" messageBody="{{ error.message }}">
                  </app-message-prompt>
                </ng-container>
            
            
                <app-message-prompt *ngIf="errorMsg" type="danger auth" mb="24px" messageTitle="Nope. Try Again."
                  messageBody="{{ errorMsg }}">
                </app-message-prompt>
            
                <button class="ss-primary-btn" [disabled]="!resetForm.valid || isProcessing">Reset Password</button>
            
              </form>
        </div>
    </div>
</div>