<div class="page-header-buffer"></div>
<page-loader *ngIf="isLoading"></page-loader>
<div class="list-page-wrapper" *ngIf="!isLoading">

    <div id="list-wrapper" class="user-lists-wrapper">

        <section *ngIf="listSongs && listSongs.length === 0">
            <div class="lists-empty-state">
                <svg-icon [svgStyle]="{ 'height.px':48 }" style="opacity: 0.8;" src="/assets/icons/box-focused-outline.svg"></svg-icon>
                <div class="message">You Deleted All Songs from this List</div>
            </div>
        </section>

        <section *ngIf="listSongs && listSongs.length > 0">

            <div class="lists-utility-row">
                <div class="info">
                    <div class="icon" (click)="onClickBackToList()">
                        <svg-icon [svgStyle]="{ 'height.px':14 }" src="/assets/icons/arrow-right.svg" style="transform: translateY(-4px);"></svg-icon>
                    </div>
                    <div class="header">{{listData.name}}</div>
                </div>
                <div class="tools">
                     <!--TO DO ADD SORT-->
                     <div id="search-input" class="list-search-wrapper">
                        <input class="ss-input list-search" [(ngModel)]="searchQuery" (ngModelChange)="triggerSearchQuery()" placeholder="Search..."/>
                        <div *ngIf="searchQuery.length > 0" class="list-search-clear-chip" (click)="handleClearSearch()">
                            <svg-icon [svgStyle]="{ 'height.px':8 }" src="/assets/icons/close-solo.svg"></svg-icon>
                        </div>
                    </div>
                </div>
            </div>

            <div class="list-item" *ngFor="let s of listSongs | fullTextSearch: searchQuery:'title'" [ngStyle]="{ 'opacity': searchActive ? 1 : 0 }">
                <div class="list-detail-section" (click)="onClickSong(s)">
                    <div class="image-tiles single-tile">
                        <div class="tile single-tile">
                          <img [src]="s.albumArtURL" alt="Image" />
                        </div>
                      </div>
                    <div class="list-info">
                        <div class="song-name">{{s.title}}</div>
                        <div class="artist-name">{{s.artist}}</div>
                        <div class="list-updated">Saved: {{s.savedAt | timeAgoPipe}} ago</div>
                    </div>
                </div>
                <div class="right-section">

                    <div class="identifiers">
                        <div class="identifier">
                            <div class="label">ISRC</div>
                            <div class="value">{{s.isrc}}</div>
                        </div>
                        <div class="identifier">
                            <div class="label">ISWC</div>
                            <div class="value">{{s?.workData?.iswc || 'NA'}}</div>
                        </div>
                    </div>

                    <div class="icon" (click)="onClickRemoveSong(s)">
                        <svg-icon [svgStyle]="{ 'height.px':18 }" src="/assets/icons/trash-outline.svg" style="transform: translateY(1px);"></svg-icon>
                    </div>
                </div>
            </div>

            <div class="lists-empty-state" *ngIf="(listSongs | fullTextSearch: searchQuery:'title').length === 0" style="opacity: 1;">
                <svg-icon [svgStyle]="{ 'height.px':48 }" style="opacity: 0.8;" src="/assets/icons/box-focused-outline.svg"></svg-icon>
                <div class="message" style="opacity: 1;">Nothing Matching Your Search</div>
            </div>

            <div class="delete-list-wrapper">
                <div class="content">
                    <svg-icon *ngIf="!isRemovingFromList" [svgStyle]="{ 'height.px':14 }"
                    src="/assets/icons/trash-outline.svg" style="transform: translateY(1px);"></svg-icon>
                    <a class="delete-text" (click)="onClickDeleteList()">Delete List</a>
                </div>
            </div>

            <app-confirm-action
            *ngIf="showDeleteList"
            title="Delete List"
            msg="This list and all songs will be removed."
            actionText="Yes, Delete List"
            (onConfirm)="handleDeleteList()"
            (onCancel)="handleCancelDeleteList()"
            ></app-confirm-action>
        </section>

    </div>
</div>