<div class="track-view-header-buffer"></div>
<page-loader *ngIf="isLoadingTrack"></page-loader>
<div id="track-card" class="track-card-wrapper" *ngIf="!isLoadingTrack && trackData">

    <div id="track-header" class="track-card-header">

        <div class="track-info">

            <div class="art-work">
                <img class="album-art" [src]="trackData?.albumArtURL" />
            </div>

            <div class="track-detail">
                <div class="title">{{trackData?.title || 'undefined'}}</div>
                <div class="artist">{{trackData.artist}}</div>
                <div class="audio-player">
                    <micro-player [audioUrl]="trackData.previewURL"></micro-player>
                </div>
            </div>

        </div>
        <div class="track-actions">
            <div class="action-button" *ngIf="!isListMode">
                <div class="primary-area" (click)="onClickShowSaveOptions()">
                    <svg-icon [svgStyle]="{ 'height.px':14 }" src="/assets/icons/save-disk.svg"
                        style="transform: translateY(1px);"></svg-icon>
                    Save
                </div>
            </div>
            <!-- <div class="action-button danger" *ngIf="isListMode">
                <div class="primary-area" (click)="onClickRemoveFromList()">
                    <svg-icon *ngIf="!isRemovingFromList" [svgStyle]="{ 'height.px':14 }"
                        src="/assets/icons/trash-outline.svg" style="transform: translateY(1px);"></svg-icon>
                    <span *ngIf="!isRemovingFromList">Remove</span>
                    <span *ngIf="isRemovingFromList" class="ss-spinner small danger"></span>
                </div>
            </div> -->
            <div class="action-button">
                <div class="primary-area" (click)="onClickCloseSongView()">
                    <svg-icon [svgStyle]="{ 'height.px':13 }" src="/assets/icons/close-solo.svg"
                        style="transform: translateY(1px);"></svg-icon>
                    Close
                </div>
            </div>
        </div>
    </div>

    <div class="attribution-bar">
        Track Data from
        <div class="logo-wrapper">
            <div class="link-icon"><svg-icon [svgStyle]="{ 'height.px':16 }"
                src="/assets/icons/spotify-icon.svg"></svg-icon></div>
        </div>
        <span class="heavy">Spotify</span>
        <a [href]="trackData.trackSourceURL" target="_blank">Source</a>
    </div>

    <div class="missing-data-alert" *ngIf="isTrackMissingAllData">
        <div class="icon">
            <svg-icon [svgStyle]="{ 'height.px':18 }"
            src="/assets/icons/recordings-square.svg"></svg-icon>
        </div>
        <div class="message">
            Some tracks, especially on new releases, won't have data yet. We will monitor this ISRC and have flagged it internally.  
        </div>
    </div>

    <div class="identifier-row">
        <div class="identifier-card">
            <div *ngIf="showCopiedISRC" class="copied-message">COPIED</div>
            <div class="data">
                <div class="label">ISRC</div>
                <div class="value">{{trackData.isrc || 'Unknown'}}</div>
            </div>
            <div *ngIf="trackData.isrc" class="copy-wrapper" (click)="copyToClipboard('isrc', trackData?.isrc)">
                <svg-icon *ngIf="!showCopiedISRC" [svgStyle]="{ 'height.px':16 }"
                    src="/assets/icons/copy-to-clipboard.svg"></svg-icon>
                <svg-icon *ngIf="showCopiedISRC" [svgStyle]="{ 'height.px':16 }"
                    src="/assets/icons/copied-to-clipboard.svg"></svg-icon>
            </div>
        </div>
        <div class="identifier-card">
            <div *ngIf="showCopiedISWC" class="copied-message">COPIED</div>
            <div class="data">
                <div class="label">ISWC</div>
                <div *ngIf="isLoadingISWC" class="ss-spinner small passive"></div>
                <div class="value" *ngIf="!isLoadingISWC && trackData?.workData?.iswc">
                    {{trackData?.workData?.iswc}}
                </div>
                <div class="value" *ngIf="!isLoadingISWC && trackData?.mlcWorkData?.iswc && !trackData?.workData?.iswc">
                    {{trackData?.mlcWorkData?.iswc}}
                </div>
                <div *ngIf="!isLoadingISWC && !trackData?.workData?.iswc && !trackData?.mlcWorkData?.iswc" class="value">N/A</div>
            </div>
            <div *ngIf="trackData?.workData?.iswc || trackData?.mlcWorkData?.iswc" class="copy-wrapper"
                (click)="copyToClipboard('iswc', trackData?.workData?.iswc ? trackData?.workData?.iswc : trackData?.mlcWorkData?.iswc)">
                <svg-icon *ngIf="!showCopiedISWC" [svgStyle]="{ 'height.px':16 }"
                    src="/assets/icons/copy-to-clipboard.svg"></svg-icon>
                <svg-icon *ngIf="showCopiedISWC" [svgStyle]="{ 'height.px':16 }"
                    src="/assets/icons/copied-to-clipboard.svg"></svg-icon>
            </div>
        </div>
    </div>

    <div class="primary-data-wrapper">

        <div class="data-col">

            <section *ngIf="trackData">
                <div class="data-card-label">ARTIST</div>
                <div class="data-card">
                    <div class="visual">
                        <div class="icon">
                            <svg-icon [svgStyle]="{ 'height.px':16 }" src="/assets/icons/team-outline.svg"
                                style="transform: translateY(2px);"></svg-icon>
                        </div>
                    </div>
                    <div class="content">
                        <!-- <div class="primary-data">{{trackData.recordingData.mainArtist?.name || trackData.artist}}</div> -->
                        <div class="primary-data">{{trackData.artist}}</div>
                        <!-- <div class="secondary-data">ISNI: {{trackData.recordingData.mainArtist?.ids?.isni || 'undefined'}}</div> -->
                    </div>
                </div>
    
                <div class="data-card-label">RECORD LABEL</div>
                <div class="data-card">
                    <div class="visual">
                        <div class="icon">
                            <svg-icon [svgStyle]="{ 'height.px':16 }" src="/assets/icons/recordings-square.svg"
                                style="transform: translateY(2px);"></svg-icon>
                        </div>
                    </div>
                    <div class="content">
                        <!-- <div *ngIf="trackData.recordingData.pLine != null" class="long-data">{{trackData.recordingData.pLine}}</div> -->
                        <div *ngIf="trackData.mlcLabels != null && trackData.mlcLabels.length > 0" class="long-data">{{trackData.mlcLabels[0].name | titlecase}}</div>
                        <!-- <div *ngIf="trackData.recordingData.pLine == null && trackData.mlcLabels == null" class="long-data">Undefined</div> -->
                        <div *ngIf="trackData.mlcLabels == null || trackData.mlcLabels.length == 0" class="long-data">Undefined</div>
                    </div>
                </div>
            </section>

        </div>

        <div class="data-col">

            <div class="data-card-label">SONGWRITERS {{trackData?.workData?.songwriters ? trackData?.workData?.songwriters.length : trackData?.mlcWorkData?.writers ? trackData?.mlcWorkData?.writers.length : ''}}</div>

            <section *ngIf="trackData.workData?.songwriters">
                <div class="data-card" *ngFor="let w of trackData.workData.songwriters | slice: 0:writerLimitCount">
                    <div class="visual">
                        <div class="icon">
                            <svg-icon [svgStyle]="{ 'height.px':16 }" src="/assets/icons/user-basic.svg"
                                style="transform: translateY(2px);"></svg-icon>
                        </div>
                    </div>
                    <div class="content">
                        <div class="primary-data">{{w.name | titlecase}}</div>
                        <div class="secondary-data">
                            <span *ngIf="w.ids?.ipis && w.ids?.ipis.length > 0">
                                IPI:
                                <ng-container *ngFor="let i of w.ids.ipis; let last = last">
                                    {{ i }}<span *ngIf="!last">, </span>
                                </ng-container>
                            </span>
                            <span *ngIf="!w.ids?.ipis || w.ids?.ipis.length === 0">IPI: NA</span>
                        </div>
                        <div class="secondary-data">ISNI: {{w.ids.isni || 'NA'}}</div>
                    </div>
                </div>

                <div class="expand-row" *ngIf="trackData.workData.songwriters.length > 3">
                    <div *ngIf="writerLimitCount == 3" class="expand-btn" (click)="writerLimitCount = 100">
                        View {{trackData.workData.songwriters.length - writerLimitCount}} More
                    </div>
                    <div *ngIf="writerLimitCount > 3" class="expand-btn" (click)="writerLimitCount = 3">View Less</div>
                </div>

            </section>

            <section *ngIf="trackData.mlcWorkData && trackData.mlcWorkData.writers && !trackData.workData?.songwriters">
                <div class="data-card" *ngFor="let w of trackData.mlcWorkData.writers | slice: 0:writerLimitCount">
                    <div class="visual">
                        <div class="icon">
                            <svg-icon [svgStyle]="{ 'height.px':16 }" src="/assets/icons/user-basic.svg"
                                style="transform: translateY(2px);"></svg-icon>
                        </div>
                    </div>
                    <div class="content">
                        <div class="primary-data">{{w.fullName | titlecase}}</div>
                        <div class="secondary-data">
                            IPI: {{w.ipiNameNumber || 'Undefined'}}
                        </div>
                        <!-- <div class="secondary-data">ISNI: {{w.ids.isni || 'NA'}}</div> -->
                    </div>
                </div>

                <div class="expand-row" *ngIf="trackData.mlcWorkData.writers.length > 3">
                    <div *ngIf="writerLimitCount == 3" class="expand-btn" (click)="writerLimitCount = 100">
                        View {{trackData.mlcWorkData.writers.length - writerLimitCount}} More
                    </div>
                    <div *ngIf="writerLimitCount > 3" class="expand-btn" (click)="writerLimitCount = 3">View Less</div>
                </div>
            </section>

            <section *ngIf="!trackData?.mlcWorkData?.writers && !trackData?.workData?.songwriters">
                <div class="data-card">
                    <div class="data-card-empty-state">No Writers to Display</div>
                </div>
            </section>

            <div class="data-card-label">PUBLISHING COMPANIES: {{(trackData.mlcWorkData && trackData.mlcWorkData?.publishers) ? trackData.mlcWorkData.publishers.length : ''}}</div>

            <section *ngIf="trackData.mlcWorkData && trackData.mlcWorkData?.publishers">
                <div class="data-card" *ngFor="let p of trackData.mlcWorkData.publishers | slice: 0:pubLimitCount">
                    <div class="visual">
                        <div class="icon">
                            <svg-icon [svgStyle]="{ 'height.px':16 }" src="/assets/icons/org-building.svg"
                                style="transform: translateY(2px);"></svg-icon>
                        </div>
                    </div>
                    <div class="content">
                        <div class="primary-data">{{p.name | titlecase}}</div>
                        <div class="secondary-data">IPI: {{p.ipiNameNumber || 'Undefined'}}</div>
                        <div class="secondary-data">Share: {{p.rightSharePercentage || 'Undefined '}}%</div>
                        <!-- <div class="secondary-data">Contact: {{p.contact.email || 'Undefined '}}</div> -->

                        <div class="on-behalf-of-wrapper" *ngIf="p.isAdministratorFor != null">
                            <div class="label">Administration for:</div>
                            <div class="primary">{{p.isAdministratorFor.name | titlecase}}</div>
                            <div class="secondary">IPI: {{p.isAdministratorFor.ipiNameNumber || 'Undefined'}}</div>
                        </div>
                    </div>
                </div>

                <div *ngIf="trackData.mlcWorkData.publishers.length > 3" class="expand-row">
                    <div *ngIf="pubLimitCount == 3" class="expand-btn" (click)="pubLimitCount = 100">
                        View {{trackData.mlcWorkData.publishers.length - pubLimitCount}} More</div>
                    <div *ngIf="pubLimitCount > 3" class="expand-btn" (click)="pubLimitCount = 3">View Less</div>
                </div>

            </section>

            <section *ngIf="!trackData?.mlcWorkData?.publishers">
                <div class="data-card">
                    <div class="data-card-empty-state">No Publishers to Display</div>
                </div>
            </section>

            <!-- <div class="data-card-btn" (click)="onClickCheckSongView(trackData.workData.iswc)">
                <div class="btn-row">
                    <div class="primary-data">Lookup on SongView</div>
                    <svg-icon [svgStyle]="{ 'height.px':16 }" src="/assets/icons/link-out.svg"
                        style="transform: translateY(2px);"></svg-icon>
                </div>
            </div> -->

            <div class="links-area">
                <div class="link-block" (click)="onClickCheckSongView(trackData?.workData?.iswc ? trackData?.workData?.iswc : trackData?.mlcWorkData?.iswc ? trackData?.mlcWorkData?.iswc : '')">
                    <div class="text">Open SongView</div>
                    <div class="icon">
                        <svg-icon [svgStyle]="{ 'height.px':13 }" src="/assets/icons/link-out.svg"
                        style="transform: translateY(2px);"></svg-icon>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>

<div class="user-lists-overlay" *ngIf="showListOverlay">
    <div class="user-lists-overlay-content">

        <div class="title">Save to List</div>

        <type-ahead *ngIf="userLists.length > 0 && !isNewList" [items]="userLists"
            (itemSelected)="handleListSelected($event)" label="Your Lists:" placeholder="Your lists..."
            (onEmptyStateSelect)="handleEmptyStateListAction($event)" emptyMessage="Start a new list with this name"
            isEmptyAction="true">
        </type-ahead>

        <div class="ss-form-group mb24" *ngIf="isNewList">
            <label>New List</label>
            <input class="ss-input expanded" [(ngModel)]="newListName" placeholder="Name this list" />
        </div>

        <div class="link-action-wrapper">
            <a (click)="isNewList = !isNewList">
                <span *ngIf="!isNewList">+ Create New List</span>
                <span *ngIf="isNewList">Cancel</span>
            </a>
        </div>

        <button class="ss-primary-btn expanded"
            [disabled]="(isNewList && newListName.length < 1) || (!isNewList && !selectedList) || isSavingToList"
            (click)="onClickSaveToList()">
            <span *ngIf="!isSavingToList">Save</span>
            <span class="ss-spinner btn-spinner" *ngIf="isSavingToList"></span>
        </button>

        <div class="cancel-wrap">
            <a class="cancel" (click)="showListOverlay = !showListOverlay">Cancel</a>
        </div>

    </div>
</div>