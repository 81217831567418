<div class="ss-auth-layout">
  <div class="brand-text" style="cursor: default;" routerLink="/login">SONG</div>

  <div class="ss-auth-presentation">

    <div class="info-card">
      <div class="title-wrapper">
        <div class="word-wrapper">
          <div id="sw1" class="word-swiper one">
            <div class="swipe-text">Beta</div>
          </div>
          <div class="word">Phase 1</div>
        </div>
        <div class="word-wrapper">
          <div id="sw2" class="word-swiper two">
            <div class="swipe-text">Ended</div>
          </div>
          <div class="word">Complete</div>
        </div>
      </div>

      <div class="message-wrapper">
        <div class="message">
          Huge thanks to everyone that participated in the open beta.
        </div>

        <div class="message">
          We've learned a lot that will help us continue to iterate on this site as well as APIs and Services.
        </div>

        <div class="message">
          Registrations are now suspended until the next Beta Cycle.
        </div>
      </div>
    </div>

    <div class="auth-area">

      <div class="ss-auth-content ss-modal-card" *ngIf="isYouShallNotPass">
        <div id="header" class="ss-auth-header mb24">
          Access Code
        </div>

        <div class="pass-content">
          <div class="ss-form-group">
            <input class="ss-input expanded mb24" placeholder="Enter Access Code" autocomplete="off"
              [(ngModel)]="secretPhrase" />
              <app-message-prompt *ngIf="invalidCode" type="danger auth" mb="24px" messageTitle=""
              messageBody="Invalid or Expired Access Code">
            </app-message-prompt>
            <button class="ss-primary-btn" [disabled]="isCheckingCode"
              (click)="onClickProcessSecret()">Continue</button>
          </div>
        </div>

      </div>

      <div *ngIf="isProcessing || isProcessingProvider" class="ss-spinner page"></div>
      <div id="card" *ngIf="!isProcessingProvider && !isProcessing && !isYouShallNotPass" class="ss-auth-content ss-modal-card">
        <div id="header" class="ss-auth-header mb24">
          Create Account
        </div>
        <div class="ss-auth-form">
          <form class="form" [formGroup]="userForm"
            (ngSubmit)="signUp(userForm.value.name, userForm.value.email, userForm.value.password)">

            <div class="ss-form-group">
              <input id="name" class="ss-input expanded " type="text" autocomplete="name" formControlName="name"
                placeholder="Your Name" (focus)="onFieldIn()" [ngClass]="{ 'idle' : isPreparing }" />
            </div>

            <div class="ss-form-group">
              <input id="email" class="ss-input expanded " type="email" autocomplete="off" formControlName="email"
                placeholder="you@something.com" (focus)="onFieldIn()" autocomplete="username"
                [ngClass]="{ 'idle' : isPreparing }" />
            </div>

            <div class="ss-form-group">
              <input id="password" class="ss-input expanded " type="password" formControlName="password"
                placeholder="Set Password" autocomplete="off" (focus)="onFieldIn()" required autocomplete="new-password"
                [ngClass]="{ 'idle' : isPreparing }" />
              <div id="validator-bar" class="validator-bar" [ngStyle]="{'opacity': isPreparing ? 0 : 1}">
                <div>
                  <span class="value"
                    [ngClass]="{'valid' : !userForm.get('password').hasError('specialChar') && userForm.get('password').value.length > 0}">Special
                    Char</span>
                  &nbsp;/&nbsp;
                  <span class="value"
                    [ngClass]="{'valid' : !userForm.get('password').hasError('hasNumber') && userForm.get('password').value.length > 0}">Number</span>
                </div>
                <div class="value" [ngClass]="{'valid' : userForm.get('password').value.length >= 8}">
                  8+ Characters
                </div>
              </div>
            </div>

            <ng-container *ngFor="let error of errorMsgs.email">
              <app-message-prompt *ngIf="userForm.get('email').hasError(error.type) && userForm.get('email').touched"
                type="warning" mb="24px" messageTitle="Check your email" messageBody="{{ error.message }}">
              </app-message-prompt>
            </ng-container>


            <app-message-prompt *ngIf="errorMsg" type="danger auth" mb="24px" messageTitle="Nope. Try Again."
              messageBody="{{ errorMsg }}">
            </app-message-prompt>

            <button id="sign-up-button" class="ss-primary-btn mt32" [disabled]="!userForm.valid || isProcessing"
              [ngClass]="{ 'idle' : isPreparing }">Start 7 Day Trial</button>

            <!-- <hr class="mt24 mb24" />

            <div id="o-auth" class="ss-secondary-btn o-auth" (click)="signUpGoogle()"
              [ngClass]="{ 'idle' : isPreparing }">
              <svg-icon src="/assets/icons/google.svg"></svg-icon>
              Continue with Google
            </div> -->

          </form>
        </div>

        <!-- <div class="ss-terms-prompt-wrapper">
          By continuing you agree to these
          <a routerLink="/terms">Terms</a> and
          <a routerLink="/privacy">Privacy Policy</a>
        </div> -->

      </div>
    </div>

  </div>
</div>