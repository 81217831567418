import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';
import { getAuth, createUserWithEmailAndPassword, updateProfile, GoogleAuthProvider, signInWithPopup, sendEmailVerification } from "firebase/auth";
import { UserService } from 'src/app/services/user.service';
import { serverTimestamp } from 'firebase/firestore'
import { CreationService } from 'src/app/services/creation.service';
import { fakeAsync } from '@angular/core/testing';
import { Title, Meta } from '@angular/platform-browser';
import anime from 'animejs/lib/anime.es';

const PASS_PHRASE = 'sxsw24';
const PASS_PHRASE2 = 'volume11';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  userForm: UntypedFormGroup;
  errorMsg = '';
  isProcessing = false;
  hasAnimated = false;
  auth: any;
  provider = new GoogleAuthProvider();
  isProcessingProvider = false;
  isStandardRegistration = false;
  isPreparing: boolean = true;
  invalidCode: boolean = false;
  isCheckingCode: boolean = false;

  isYouShallNotPass: boolean = true;
  isShowSecretPanel: boolean = false;
  secretPhrase: string = '';

  errorMsgs = {
    name: [
      {
        type: 'required',
        message: 'Enter your name...'
      },
    ],
    email: [
      {
        type: 'required',
        message: 'You kind of need one...'
      },
      {
        type: 'pattern',
        message: 'Email is not valid.'
      }
    ],
    password: [
      {
        type: 'required',
        message: 'Password is required.'
      },
      {
        type: 'minlength',
        message: 'Password length should be 6 characters long.'
      }
    ]
  };

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private creationService: CreationService,
    private titleService: Title,
    private metaService: Meta,
    private el: ElementRef,
  ) {
    this.titleService.setTitle('Song Identity | Sign Up');
    this.metaService.updateTag(
      {
        name: 'description',
        content: 'Create your account.'
      }
    );
  };

  ngOnInit() {
    this.auth = getAuth();
    this.animateMessage();

    setTimeout(() => {
      if (this.auth.currentUser) {
        this.router.navigate(['dashboard'])
      }
    }, 300);

    this.userForm = this.fb.group({
      name: new UntypedFormControl('', Validators.compose([
        Validators.required
      ])),
      email: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new UntypedFormControl('', Validators.compose([
        Validators.minLength(8),
        Validators.required,
        this.regexValidator(new RegExp('(?=.*[$@$!%*?&])'), { specialChar : true}),
        this.regexValidator(new RegExp('(?=.*[0-9])'), { hasNumber : true})
        // Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
      ])),
    });
  }

  onClickProcessSecret() {
    this.isCheckingCode = true;
    this.invalidCode = false;
    setTimeout(() => {
      if (this.secretPhrase.toLocaleLowerCase() === PASS_PHRASE || this.secretPhrase.toLocaleLowerCase() === PASS_PHRASE2) {
        this.isShowSecretPanel = false;
        this.isYouShallNotPass = false;
        this.isCheckingCode = false;
        this.animateRegistration();
      } else {
        this.invalidCode = true;
        this.isCheckingCode = false;
      }
    }, 1000);
  }

  signUpGoogle() {
    signInWithPopup(this.auth, this.provider)
     .then((result) => {
      if (result != null && this.isStandardRegistration === false) {
      this.isProcessingProvider = true;
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const user = result.user;
      this.userService.getUserEducationsById(user.uid).then((data) => {
        if (data != null) {
          this.router.navigate(['dashboard']);
          this.isProcessingProvider = false;
        } else {
          updateProfile(this.auth.currentUser, {
            displayName: user.displayName
          }).then(() => {
            this.creationService.createInitialUserContent(user).then((res) => {
              if (res = 'success') {
                this.router.navigate(['dashboard']);
                this.isProcessingProvider = false;
              }
            });
          }).catch((error) => {
            this.errorMsg = error.message;
            this.isProcessingProvider = false;
          });
        }
      })
      } else {
        this.isProcessingProvider = false;
      }
    }).catch((error) => {
      console.log('REDIRECT ERROR ' + error)
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.email;
      const credential = GoogleAuthProvider.credentialFromError(error);
    });
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  signUp(name, email, password) {
    this.isStandardRegistration = true;
    this.isProcessing = true;
    createUserWithEmailAndPassword(this.auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      this.errorMsg = '';
      updateProfile(this.auth.currentUser, {
        displayName: name
      }).then(() => {
        sendEmailVerification(this.auth.currentUser);
        this.creationService.createInitialUserContent(user).then((res) => {
          if (res = 'success') {
            this.router.navigate(['dashboard']);
            this.isProcessingProvider = false;
            this.isStandardRegistration = false
          }
        });
      }).catch((error) => {
        this.errorMsg = error.message;
      });
    })
    .catch((error) => {
      const errorCode = error.code;
      if (error.message = 'Firebase: Error (auth/email-already-in-use).') {
        this.errorMsg = 'Unable to create an account with those credentials';
      } else {
        this.errorMsg = error.message;
      }
      this.isProcessing = false;
      this.isStandardRegistration = false
    });
  }

  onFieldIn() {
    this.errorMsg = '';
  }

  onPasswordFieldIn() {
    this.errorMsg = '';
  }

  onPasswordFieldOut() {
    this.errorMsg = '';
  }

  animateRegistration() {

    setTimeout(() => {
      this.isPreparing = false
    }, 900)

  }

  animateMessage() {
    const animation = anime.timeline({ autoplay: false, loop: false });

    setTimeout(() => {
      const sw1 = this.el.nativeElement.querySelector('#sw1');
      const sw2 = this.el.nativeElement.querySelector('#sw2');

      animation
        .add({
          targets: [sw1, sw2],
          width: 0,
          opacity: [1, 0.9, 0],
          easing: 'easeOutCubic',
          duration: 1200,
          delay: anime.stagger(400),
          complete: () => this.isPreparing = false,
        })
      animation.play();
    }, 300)

  }

}
