import { Injectable } from '@angular/core';
import { QUANSIC_URLS } from '../constants/api-urls';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuansicService {

  constructor(private http: HttpClient) {}

  // getDataFromISRC(isrc: string): Observable<any> {
  //   if (isrc) {
  //     const requestBody = {
  //       isrc,
  //     };

  //     return this.http.post<any>(QUANSIC_URLS.quansicGetISRC, requestBody);
  //   } else {
  //     throw new Error('Invalid Data URL');
  //   }
  // };

  // getDataFromISWC(iswc: string): Observable<any> {
  //   if (iswc) {
  //     const requestBody = {
  //       iswc,
  //     };

  //     return this.http.post<any>(QUANSIC_URLS.quansicGetISWC, requestBody);
  //   } else {
  //     throw new Error('Invalid Data URL');
  //   }
  // };

}
