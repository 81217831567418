import { environment } from "src/environments/environment"

export const FreeTier = {
    name: "Free",
    price: "",
    description: "Thanks for giving the app a test drive!",
    benefits: [
        "Team Switchchord",
    ],
    showUpgrade: true,
    showManage: false
}

export const BetaTier = {
    name: "Beta Tester",
    price: "",
    description: "Thanks for giving the app a test drive! We look forward to feedback about your experience as you incorporate Song Identity into your current workflows.",
    benefits: [
        "Team Switchchord",
    ],
    showUpgrade: true,
    showManage: false
}

export const TrialTier = {
    name: "Free Trial",
    price: "",
    description: "3 days of free access to the web app, chrome extension, and IOS companion. Enjoy your access!",
    benefits: [
        "Team Switchchord",
    ],
    showUpgrade: true,
    showManage: false
}

export const NoSubscription = {
    name: "No Subscription",
    price: "",
    description: "To continue using Song Identity tools, upgrade your account to a paid tier.",
    benefits: [
        "Web App Access",
        "Chrome Extension for Web Players",
        "IOS App for Instant Listening Results",
    ],
    showUpgrade: true,
    showManage: false
}

export const SubscriptionTiers = [
    {
        name: "Monthly",
        price: "14.99/mo",
        description: "Monthly access for unlimited lookups with Song Identity tools",
        benefits: [
            "Web App Access",
            "Chrome Extension for Web Players",
            "IOS App for Instant Listening Results",
        ],
        showUpgrade: false,
        showManage: true,
        priceCode: environment.stripeProducts.monthly
    },
    {
        name: "Yearly",
        price: "149.99/yr",
        description: "A full year of access. Unlimited lookups with Song Identity tools",
        benefits: [
            "Web App Access",
            "Chrome Extension for Web Players",
            "IOS App for Instant Listening Results",
        ],
        showUpgrade: false,
        showManage: true,
        priceCode: environment.stripeProducts.yearly
    }
]