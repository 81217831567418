import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import {
  getFirestore,
} from 'firebase/firestore';

export interface PublicProfile {
  displayName: string;
  photoURL: string;
  grandfathered?: boolean;
  signUpDate?: any;
}

@Injectable({
  providedIn: 'root'
})
export class CreationService {
  database = getFirestore()

  constructor(
    private userService: UserService,
  ) { }

  async createInitialUserContent(user) {
    const profileData = {
      id: user.uid,
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL ? user.photoURL : '',
      grandfathered: false,
      createdAt: new Date,
    }

    const creationResult = new Promise((resolve, reject) => {
      this.userService.createUserPublicProfile(profileData, user.uid)
        .then(() => {
          resolve('success');
        })
        .catch((e) => {
          console.log('creation service error' + e);
          reject(e);
        })
        .catch((e) => {
          console.log('creation service error' + e);
          reject(e);
        });
  });
    return creationResult;
  }

}
