import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegistrationComponent } from './pages/auth/registration/registration.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { ResetComponent } from './pages/auth/reset/reset.component';
import { OnboardComponent } from './pages/onboard/onboard.component';
import { AccountComponent } from './pages/portal/account/account.component';
import { DashboardComponent } from './pages/portal/dashboard/dashboard.component';
import { SubscribeComponent } from './pages/portal/subscribe/subscribe.component';
import { PrivacyComponent } from './pages/public/privacy/privacy.component';
import { TermsComponent } from './pages/public/terms/terms.component';
import { AuthGuard } from './services/auth-gaurd.service';
import { SavedSongsComponent } from './pages/portal/saved-songs/saved-songs.component';
import { SongViewComponent } from './pages/portal/song-view/song-view.component';
import { SavedSongsListComponent } from './pages/portal/saved-songs-list/saved-songs-list.component';
import { ListenComponent } from './pages/portal/listen/listen.component';
import { ProcessingComponent } from './pages/portal/processing/processing.component';
import { BigNodeComponent } from './pages/public/big-node/big-node.component';
import { RequestAccessComponent } from './pages/auth/request-access/request-access.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  // {
  //   path: '**',
  //   redirectTo: '/login',
  //   pathMatch: 'full',
  // },
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'sign-up',
    component: RegistrationComponent
  },
  {
    path: 'reset',
    component: ResetComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'request-access',
    component: RequestAccessComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'songs',
    component: SavedSongsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'list/:listId',
    component: SavedSongsListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'list/:listId/:id1',
    component: SongViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'song/:id1',
    component: SongViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'listening/:appleId',
    component: ListenComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'activate-subscription',
    component: OnboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'subscribe',
    component: SubscribeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'processing',
    component: ProcessingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  { path: '**', component: BigNodeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
