export const SPOTIFY_URLS = {
    spotifyTokenURL: 'https://us-central1-scsongconnector.cloudfunctions.net/getSpotifyToken',
    spotifyTrackURL: 'https://us-central1-scsongconnector.cloudfunctions.net/getSpotifyDataByTrackId',
    spotifySearchURL: 'https://us-central1-scsongconnector.cloudfunctions.net/getSpotifyDataByTrackName',
};

export const QUANSIC_URLS = {
    quansicGetISRC: 'https://us-central1-scsongconnector.cloudfunctions.net/getQuansicDataByISRC',
    quansicGetISWC: 'https://us-central1-scsongconnector.cloudfunctions.net/getQuansicDataByISWC',
    quansicGetWriterByIPI: 'https://x1-api.quansic.com/api/v1/party?ipi=',
    quansicGetWriterByQID: 'https://x1-api.quansic.com/api/v1/party/',
};

export const TIDAL_URLS = {
    tidalTokenURL: 'https://us-central1-scsongconnector.cloudfunctions.net/getTidalToken',
    tidalSearchURL: 'https://us-central1-scsongconnector.cloudfunctions.net/getTidalDataByQuery',
};

export const MLC_URLS = {
    getDataFromMLC: 'https://us-central1-scsongconnector.cloudfunctions.net/getMLCData',
};

export const APPLE_URLS = {
    getDataFromAppleId: 'https://us-central1-scsongconnector.cloudfunctions.net/getDataByAppleId',
    getAppleToken: 'https://us-central1-scsongconnector.cloudfunctions.net/getAppleToken',
};
