import { Component } from '@angular/core';

@Component({
  selector: 'app-account-idv',
  templateUrl: './account-idv.component.html',
  styleUrl: './account-idv.component.scss'
})
export class AccountIdvComponent {

}
