import { Injectable } from '@angular/core';
import { getAnalytics, logEvent } from "firebase/analytics";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

   analytics = getAnalytics();

  constructor() { }

  captureEvent(event) {
    logEvent(this.analytics, event);
  }
}
