<div class="page-header-buffer"></div>
<div #header class="ss-nav" [ngStyle]="{ 'visibility': showPrimaryNav ? 'visible' : 'hidden' }" [ngClass]="{ 'idle' : !user }">
    <div class="ss-nav-items-wrapper">
        <div class="ss-nav-left">
            <div class="ss-logo-wrapper" (click)="onClickLogo()">
                <div class="brand-text" style="cursor: default;">SONG</div>
                <!-- <span style="font-weight: 400">IDENTITY</span> -->
            </div>
            <div *ngIf="showNavLinks && user" class="link-wrapper" [ngClass]="{'all-inactive' : activeLinkSpace === 'account'}">
                <div class="link" (click)="onClickMainMenu('dashboard')">
                    Discover
                    <div class="link-bar-wrapper right" [class.active]="activeLinkSpace === 'dashboard' || activeLinkSpace === 'song' || activeLinkSpace === 'listening'" [ngClass]="{'previous' : previousLinkSpace === 'dashboard'}"><div class="bar"></div></div>
                </div>
                <div class="link" (click)="onClickMainMenu('songs')">
                    Saved
                    <div class="link-bar-wrapper left" [class.active]="activeLinkSpace === 'songs' || activeLinkSpace === 'list'" [ngClass]="{'previous' : previousLinkSpace === 'songs'}"><div class="bar"></div></div>
                </div>
            </div>
        </div>
        <div *ngIf="!isAuthenticated && !isLoading" class="ss-nav-links">
            <!-- <a class="nav-link" routerLink="/login">
                Login
            </a> -->
            <!-- <a class="nav-link-btn" routerLink="/sign-up">
                Get Access
            </a> -->
        </div>
        <div *ngIf="isAuthenticated && !isLoading && user && showAvatarSection" class="ss-avatar-wrapper" (click)="onShowUserMenu()">
            <div class="ss-nav-user-info">
                <div class="email">{{user.displayName}}</div>
                <div class="name">{{user.email}}</div>
            </div>
            <div class="ss-nav-avatar">
                <img class="avatar-img" *ngIf="userProfile?.photoURL" [src]="userProfile?.photoURL" (error)="userProfile.photoURL = '/assets/images/img_placeholder.png' ">
                <img *ngIf="!userProfile?.photoURL" src="/assets/images/img_placeholder.png" height="100%" width="100%" />
            </div>
        </div>
        <div [hidden]="!showUserMenu" class="ss-nav-user-menu" #menu>
            <div class="menu-item" (click)="onClickMenuLink('/account')">
                <svg-icon src="/assets/icons/settings-cog.svg"></svg-icon>
                Account & Settings
            </div>
            <div class="menu-item" (click)="onClickSignOut()">
                <svg-icon src="/assets/icons/logout.svg"></svg-icon>
                Logout
            </div>
        </div>
    </div>
</div>