import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SubCheckService } from 'src/app/services/sub-check.service';
import { SubMonitorService } from 'src/app/services/sub-monitor.service';

@Component({
  selector: 'app-processing',
  templateUrl: './processing.component.html',
  styleUrl: './processing.component.scss'
})
export class ProcessingComponent {

  constructor(
    private subCheckService: SubCheckService,
    private subMonitor: SubMonitorService,
    private router: Router,
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.subMonitor.setSubscription("trial");
      this.router.navigate(['dashboard']);
    }, 4000);
  }

}
