import { Component, Input, OnInit } from '@angular/core';
import * as anime from 'animejs';

@Component({
  selector: 'pop-menu',
  templateUrl: './pop-menu.component.html',
  styleUrl: './pop-menu.component.scss'
})
export class PopMenuComponent {
  @Input() menuWidth: string = '200px';
  @Input() menuItems: MenuItem[] = [];

  menuState: 'closed' | 'open' = 'closed';

  ngOnInit() {
    this.animateMenu();
  }

  toggleMenu() {
    this.menuState = this.menuState === 'closed' ? 'open' : 'closed';
    this.animateMenu();
  }

  onItemClick(item: MenuItem) {
    if (item.action) {
      item.action();
    }
    this.toggleMenu();
  }

  private animateMenu() {
    const menuElement = document.querySelector('.popover-menu');

    if (menuElement) {
      anime({
        targets: menuElement,
        width: this.menuState === 'open' ? this.menuWidth : 0,
        duration: 300,
        easing: 'easeInOutQuad',
      });
    }
  }
}

interface MenuItem {
  name: string;
  type: string;
  action?: () => void;

}
