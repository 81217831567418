import { Component } from '@angular/core';

@Component({
  selector: 'page-loader',
  templateUrl: './page-loader.component.html',
  styleUrl: './page-loader.component.scss'
})
export class PageLoaderComponent {

}
