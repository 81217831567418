<div class="ss-message-container" [ngClass]="type" [ngStyle]="{'margin-top': mt, 'margin-bottom': mb}">
  <div class="ss-message-icon">
    <!-- <svg-icon src='{{messageIcon}}'></svg-icon> -->
  </div>
  <div>
    <!-- <div class="message-title">
      {{messageTitle}}
    </div> -->
    <div class="message-body">
      {{messageBody}}
    </div>
  </div>
</div>