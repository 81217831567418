import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect } from "firebase/auth";
import { GoogleAuthProvider, getRedirectResult, updateProfile, sendEmailVerification, deleteUser } from "firebase/auth";
import { UserService } from 'src/app/services/user.service';
import { serverTimestamp } from 'firebase/firestore'
import { CreationService } from 'src/app/services/creation.service';
import { logEvent, getAnalytics } from "firebase/analytics";
import { Title, Meta } from '@angular/platform-browser';
import anime from 'animejs/lib/anime.es';
import { MicroStateService } from 'src/app/services/micro-state.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  auth: any;
  userForm!: UntypedFormGroup;
  successMsg = '';
  errorMsg = '';
  isAuthenticating = false;
  provider = new GoogleAuthProvider();
  isProcessingProvider = false;
  isPreparing: boolean = true;

  errorMsgs = {
    email: [
      {
        type: 'required',
        message: 'You need an email'
      },
      {
        type: 'pattern',
        message: 'That format doesn\'t  look right'
      }
    ],
    password: [
      {
        type: 'required',
        message: 'Password is required.'
      },
      {
        type: 'minlength',
        message: 'It doesn\'t look long enough.'
      }
    ]
  };

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private userService: UserService,
    private creationService: CreationService,
    private titleService: Title,
    private metaService: Meta,
    private el: ElementRef,
    private microState: MicroStateService,
  ) {
    this.titleService.setTitle('Song Identity | Login');
    this.metaService.updateTag(
      {
        name: 'description',
        content: 'Access your Account.'
      }
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (window.innerWidth < 800) {
        this.isPreparing = false;
      } else {
        this.animateLogin();
      }
    }, 700);
    this.auth = getAuth();

    setTimeout(() => {
      if (this.auth.currentUser) {
        this.router.navigate(['dashboard'])
      }
    }, 300);

    this.userForm = this.fb.group({
      email: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new UntypedFormControl('', Validators.compose([
        Validators.minLength(8),
        Validators.required
      ])),
    });

    this.microState.stateData$.subscribe((data) => {
      if (data != null && data?.newUserImpressed === true) {
        this.isPreparing = true;
        this.animateLogin();
      }
    });
  }

  signInGoogle() {
    signInWithPopup(this.auth, this.provider)
      .then((result) => {
        if (result != null) {
          this.isProcessingProvider = true;
          const user = result.user;
          // Check if the user was created in the last 60 seconds
          const isNewUser = user.metadata.creationTime && this.isWithinLast15Seconds(user.metadata.creationTime);

          if (isNewUser) {
            this.isProcessingProvider = false;
            // Remove the newly created user
            deleteUser(user).then(() => {
              alert('Not Accepting New Registrations at the Moment');
            }).catch((error) => {
              console.error('Error deleting new user:', error);
            });
            return;
            // updateProfile(this.auth.currentUser, {
            //   displayName: user.displayName
            // }).then(() => {
            //   this.creationService.createInitialUserContent(user).then((res) => {
            //     if (res === 'success') {
            //       this.router.navigate(['dashboard']);
            //     }
            //   });
            // }).catch((error) => {
            //   this.errorMsg = error.message;
            //   this.isProcessingProvider = false;
            // });
          } else {
            this.router.navigate(['dashboard']);
          }
        } else {
          this.isProcessingProvider = false;
        }
      })
      .catch((error) => {
        console.log('REDIRECT ERROR ' + error);
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // ...
      });
  }

  isWithinLast15Seconds(creationTime: string | undefined): boolean {
    if (!creationTime) {
      return false;
    }
    const creationTimestamp = new Date(creationTime).getTime();
    const now = Date.now();
    return now - creationTimestamp < 15 * 1000;
  }


  signIn(email: string, password: string) {
    this.isAuthenticating = true;

    signInWithEmailAndPassword(this.auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        this.router.navigate(['/dashboard']);
        this.isAuthenticating = false;
        const analytics = getAnalytics();
        logEvent(analytics, 'login');
      })
      .catch((error) => {
        const errorCode = error.code;
        if (error.message = 'Firebase: Error (auth/wrong-password).') {
          this.errorMsg = 'Email or Password is incorrect';
        } else {
          this.errorMsg = error.message;
        }
        this.isAuthenticating = false;
        console.log('error signing in')
      });
  }

  goToSignup() {
    this.router.navigate(['/sign-up']);
  }

  goToResetPassword() {
    this.router.navigate(['reset']);
  }

  onFieldIn() {
    this.errorMsg = '';
  }

  animateLogin() {
    const authAnimation = anime.timeline({ autoplay: false, loop: false });

    setTimeout(() => {
      const sw1 = this.el.nativeElement.querySelector('#sw1');
      const sw2 = this.el.nativeElement.querySelector('#sw2');
      const sw3 = this.el.nativeElement.querySelector('#sw3');

      setTimeout(() => {
        this.isPreparing = false;
      }, 500);

      authAnimation
        .add({
          targets: [sw1, sw2, sw3],
          width: 0,
          opacity: [1, 0.9, 0],
          easing: 'easeOutCubic',
          duration: 1200,
          delay: anime.stagger(400),
          // complete: () => this.isPreparing = false,
        })
      authAnimation.play();
    }, 300)

  }

}
