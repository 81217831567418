// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyD6mgA9T8XJ3sU6Y08NuGbeXfzVEGULHBM",
    authDomain: "scsongconnector.firebaseapp.com",
    projectId: "scsongconnector",
    storageBucket: "scsongconnector.appspot.com",
    messagingSenderId: "328037953111",
    appId: "1:328037953111:web:9554144595b6c943b23d12",
    measurementId: "G-LLCK9M96K6"
  },
  stripeProducts: {
    monthly: "price_1On4tbH2s7aZiF2LupasKqq8",
    yearly: "price_1On4x8H2s7aZiF2LnUP142oz"
  }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
