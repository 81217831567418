import { Injectable } from '@angular/core';
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  updateDoc,
  collection,
  query,
  getDocs,
  addDoc,
} from 'firebase/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserListsService {
  database = getFirestore();

  constructor() { }

  async getListsByUserId(id: string): Promise<any[]> {
    const userListsRef = collection(this.database, `user-profiles/${id}/user-lists`);
  
    const profileSnap = await getDocs(userListsRef);
    const lists = profileSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
    return lists;
  }

  async createUserList(userId, listData,) {
    const docRef = await addDoc(collection(this.database, "user-profiles", userId, 'user-lists'), listData);
    return docRef.id;
  }

  updateUserList(userId, listData) {
    const docRef = doc(this.database, `user-profiles/${userId}/user-lists/${listData.id}`); 
    return updateDoc(docRef, listData);
  }

  deleteUserList(userId, listId) {
    const docRef = doc(this.database, `user-profiles/${userId}/user-lists/${listId}`); 
    return deleteDoc(docRef);
  }

  async getListDataByListId(userId, listId) {
    const docRef = doc(this.database, `user-profiles/${userId}/user-lists/${listId}`);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }

  async addSongToUserList(listId, userId, songData) {
    const docRef = doc(this.database, `user-profiles/${userId}/user-lists/${listId}/saved-songs/${songData.isrc}`);
    await setDoc(docRef, songData);
    return docRef.id;
  }

  async removeSongFromUserList(userId, listId, songData) {
    const docRef = doc(this.database, `user-profiles/${userId}/user-lists/${listId}/saved-songs/${songData.isrc}`);
    return deleteDoc(docRef);
  }

  getAllSongsFromUserList(userId: string, listId: string): Observable<any[]> {
    const subColRef = collection(this.database, `user-profiles/${userId}/user-lists/${listId}/saved-songs`);
    
    const q = query(subColRef);

    return new Observable<any[]>(subscriber => {
      getDocs(q)
        .then(querySnapshot => {
          const data = querySnapshot.docs.map(doc => doc.data());
          subscriber.next(data);
          subscriber.complete();
        })
        .catch(error => {
          subscriber.error(error);
        });
    });
  }

}
