import { Component, EventEmitter, Input, Output } from '@angular/core';
import anime from 'animejs/lib/anime.es';

@Component({
  selector: 'app-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrl: './confirm-action.component.scss'
})
export class ConfirmActionComponent {
  @Input() title: string = "Are you sure?"
  @Input() msg: string = "This action cannot be undone"
  @Input() actionText: string = "Proceed"
  @Input() cancelText: string = "Cancel"

  @Output() onConfirm: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    anime({
      targets: "#content",
      opacity: [0,1],
      translateY: [24, 0],
      easing: 'easeOutElastic(3, .7)',
      duration: 700,
    });
  }

  onClickConfirm() {
    this.onConfirm.emit();
  }

  onClickCancel() {
    this.onCancel.emit();
  }
}
