import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaService: Meta
  ) {
    this.titleService.setTitle('Song Identity | Terms of Service');
    this.metaService.updateTag(
      {
        name: 'description',
        content: 'Review the Terms of Service for Song Identity'
      }
    );
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);  // TODO: revisit
  }

}
