<div class="ss-form-group">
    <label for="type-ahead">{{label}}</label>
    <input #typeAheadInput type="text" class="ss-input expanded" (input)="onInput($event.target.value)"
        [value]="selectedItem ? selectedItem.name : ''" (blur)="onBlur()" (focus)="onFocus()"
        (keyPress)="handleKeyboardEvent($event)" placeholder="{{placeholder}}" />
    <ul *ngIf="showList">
        <li *ngFor="let item of filteredItems; let i = index" [ngClass]="{ active: i === activeIndex }"
            (click)="selectItem(item)">
            <div class="flex space-between items-center">
                <div>
                    <div>{{ item.name }}</div>
                </div>
            </div>
        </li>
        <li *ngIf="filteredItems.length === 0 && !isEmptyAction" style="color: var(--ss-text-secondary); cursor: disabled;">
            {{emptyMessage}}
        </li>
        <li (click)="onSelectEmptyMessage()" *ngIf="filteredItems.length === 0 && isEmptyAction" style="color: var(--ss-text-secondary); cursor: pointer;">
            {{emptyMessage}}
        </li>
    </ul>
</div>