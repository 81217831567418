import { Component, ElementRef, EventEmitter, Output, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import anime from 'animejs/lib/anime.es';
import { MicroStateService } from 'src/app/services/micro-state.service';

@Component({
  selector: 'landing-animation',
  templateUrl: './landing-animation.component.html',
  styleUrl: './landing-animation.component.scss'
})
export class LandingAnimationComponent {

  @ViewChild('animation') animation: ElementRef;
  @ViewChild('circles') circles: ElementRef;

  @Output() onComplete: EventEmitter<any> = new EventEmitter<any>();

  wrapperStyle = {
    transform: 'translateY(-3em)',
  };

  circlesStyle = {
    display: 'flex',
    justifyContent: 'center',
    gap: '1em',
  };

  middleStyle = {
    background: '#09080b',
    height: '0px',
    width: '0px',
    borderRadius: '8px 8px 8px 33px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    overflow: 'hidden',
  };

  svgStyle = {
    opacity: 0,
  };

  circleStyle = {
    height: '12px',
    width: '12px',
    background: '#FFFFFF',
    scale: 1,
    opacity: 0,
    translateX: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  primaryCircleStyle = {
    height: '12px',
    width: '12px',
    background: '#FFFFFF',
    scale: 1,
    opacity: 0,
    translateX: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '800',
  };

  constructor(private el: ElementRef, private microState: MicroStateService, private router: Router) { }

  ngOnInit() {

    const currentPath = this.router.url;

    if (currentPath === '/login' || currentPath === '/sign-up') {
      this.handleCreateAnimation()
    }

  }

  handleCreateAnimation() {
    const registerAnimation = anime.timeline({
      autoplay: true,
      loop: false,
    });

    setTimeout(() => {
      const circles = this.el.nativeElement.querySelector('#circles');

      registerAnimation
      .add({
        targets: [circles, '.circle'],
        opacity: [0, 1],
        height: [16, 12],
        borderRadius: '50px 50px 50px 50px',
        duration: 1200,
        delay: anime.stagger(200),
        direction: 'alternate',
        easing: 'easeOutElastic(2, .6)',
      })
      .add({
        targets: '.left',
        translateX: [0, -25, 28],
        scale: [1, 2, 1],
        background: ['#FFFFFF', '#9069FF'],
        easing: 'easeOutElastic(2, .6)',
        duration: 800,
      }, 1000)
      .add({
        targets: '.right',
        translateX: [0, 25, -28],
        scale: [1, 2, 1],
        background: ['#FFFFFF', '#9069FF'],
        duration: 800,
        easing: 'easeOutElastic(2, .6)',
      }, 1000)
      .add({
        targets: '.block',
        translateX: 0,
        translateY: 0,
        rotate: 0,
        height: 70,
        width: 76,
        duration: 800,
        borderRadius: '8px 8px 8px 16px',
      }, 2100)
      .add({
        targets: '.right',
        translateX: [24, -94],
        duration: 800,
        easing: 'easeOutElastic(2, .4)',
      }, 2600)
      .add({
        targets: '.left',
        translateX: [-24, 94],
        duration: 800,
        easing: 'easeOutElastic(2, .4)',
      }, 2600)
      .add({
        targets: '.middle',
        height: '61px',
        width: '67px',
        duration: 400,
        easing: 'easeOutElastic(1, .4)',
      }, '-=400')
      .add({
        targets: '.logo-wrapper',
        opacity: [0, 1],
        translateY: [24, 0],
        duration: 900,
        easing: 'easeOutElastic(1, .4)',
      }, '-=400')
      .add({
        targets: '.outer',
        scale: [-0.5, 0.9],
        opacity: [1, 0],
        duration: 900,
        easing: 'easeOutElastic(3, .7)',
      }, 3200)
      .add({
        targets: '.logo-el',
        opacity: [0, 1],
        scale: [2, 1],
        duration: 600,
      }, '-=700')
      .add({
        targets: '.logo-wrapper',
        opacity: [1, 0],
        translateY: [0, 24],
        duration: 900,
        delay: 1000,
      })
      .add({
        duration: 500,
        complete: () =>  this.microState.setData('newUserImpressed', true),
      }, 5000)
      .add({
        targets: '.animation-content',
        opacity: [1, 0],
        duration: 800,
      }, '-=700');
  

      registerAnimation.finished.then(() => {
        this.onComplete.emit();
      });


    }, 1000)
  };

}
