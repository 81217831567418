<div class="ss-subscribe-page-wrapper">
    <div class="ss-subscribe-header-wrapper">
        <div class="ss-subscribe-header">
            Subscription Options
        </div>

        <div class="ss-subscribe-message">
            To continue using Song Identity tools and services, select an option below.
        </div>
    </div>

    <div class="ss-subscriptions">
        <div class="ss-subscription-widget" *ngFor="let p of products">
            <div class="header">
               <div class="tier">
                   {{p.name}}
               </div>
               <div class="price">
                {{p.price}}
            </div>
            </div>

            <div class="description">
                {{p.description}}
            </div>

            <div class="benefits">
                <div class="feature" *ngFor="let b of p.benefits">- {{b}}</div>
            </div>

            <button class="ss-secondary-btn mt32" [disabled]="isPreparingStore" (click)="subscribe(p)">
                <span *ngIf="!p.isProcessing">Upgrade</span>
                <div class="ss-spinner" *ngIf="p.isProcessing"></div>
            </button>            
           
        </div>
    </div>
</div>