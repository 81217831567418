import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
interface Item {
  name: string;
}
@Component({
  selector: 'type-ahead',
  templateUrl: './type-ahead.component.html',
  styleUrls: ['./type-ahead.component.scss']
})
export class TypeAheadComponent {
  @Input() items: Item[] = [];
  @Input() preSelectedItem: any;
  @Input() label: String;
  @Input() placeholder: String;
  @Input() emptyMessage: String = "No Items to Show";
  @Input() isEmptyAction: boolean = false;

  @Output() itemSelected: EventEmitter<Item> = new EventEmitter<Item>();
  @Output() onEmptyStateSelect: EventEmitter<any> = new EventEmitter<Item>();
  @ViewChild('typeAheadInput', { static: false }) typeAheadInput: ElementRef<HTMLInputElement>;

  filteredItems: Item[] = [];
  selectedItem: Item | null = null;
  showList: boolean = false;
  activeIndex: number = -1;

  private keyDownSubject: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  constructor(private elementRef: ElementRef) {
    // Subscribe to the debounced keydown event
    this.keyDownSubject.pipe(debounceTime(50)).subscribe((event) => {
      this.handleKeyboardEvent(event);
    });
  };

  ngOnInit() {
    if (this.items === undefined) {
      this.items = [];
    }
  };

  filterItems(value: string) {
    if (this.items.length === 0) {
      return;
    }
    if (!value) {
      this.filteredItems = [];
      return;
    }
    const filterValue = value.toLowerCase();
    this.filteredItems = this.items.filter((item) =>
      item.name.toLowerCase().includes(filterValue)
    );
  }
  onInput(value: string) {
    this.filterItems(value);
    this.showList = true;
    this.activeIndex = -1;
  }
  onFocus() {
    if (!this.showList) {
      this.showList = true;
      this.typeAheadInput.nativeElement.value = '';
      this.filteredItems = this.items;
    }
  }
  clearTypeAhead() {
    this.typeAheadInput.nativeElement.value = '';
    this.showList = false;
  }
  onBlur() {
    if (this.showList) {
      setTimeout(() => {
        this.showList = false;
      }, 300);
    }
  };

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Debounce the keydown event
    this.keyDownSubject.next(event);
  };

  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.items.length === 0) {
      return;
    }
    if (this.activeIndex === - 1 && event.key === 'ArrowUp') {
      return;
    }
    event.preventDefault();
    if (this.showList && this.filteredItems.length > 0) {
      if (event.key === 'ArrowUp') {
        this.activeIndex = this.activeIndex - 1;
      } else if (event.key === 'ArrowDown') {
        this.activeIndex = this.activeIndex + 1;
      } else if (event.key === 'Enter') {
        this.selectItem(this.filteredItems[this.activeIndex]);
      }
    }
  };

  selectItem(item: Item) {
    this.selectedItem = item;
    this.showList = false;
    this.itemSelected.emit(item);
  };

  onSelectEmptyMessage() {
    const currentValue =  this.typeAheadInput.nativeElement.value;
    this.onEmptyStateSelect.emit(currentValue);
    this.typeAheadInput.nativeElement.value = '';
  }

}