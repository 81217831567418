import { Component, ElementRef, HostListener, Input, NgZone, ViewChild } from '@angular/core';
import anime from 'animejs/lib/anime.es';
import { QuansicService } from 'src/app/services/quansic.service';
import { MicroStateService } from 'src/app/services/micro-state.service';
import { UserListsService } from 'src/app/services/user-lists.service';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { DiscoveryService } from 'src/app/services/discovery.service';
import { ClipboardService } from 'ngx-clipboard';
import { MlcServiceService } from 'src/app/services/mlc-service.service';
import { Title } from '@angular/platform-browser';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { SongService } from 'src/app/services/song.service';

@Component({
  selector: 'app-song-view',
  templateUrl: './song-view.component.html',
  styleUrl: './song-view.component.scss'
})
export class SongViewComponent {
  trackData: any;
  user: any;

  isLoadingTrack: boolean = true;
  isLoadingISRC: boolean = true;
  isLoadingISWC: boolean = true;

  isPreviewAvailable: boolean = false;
  isTrackMissingAllData: boolean = false;

  spotifyToken: any;

  discoveredDataISRC: any;
  discoveredDataISWC: any;

  showListOverlay: boolean = false;
  isLoadingLists: boolean = false;
  isNewList: boolean = false;
  newListName: string = '';

  selectedList: any;
  isSavingToList: boolean = false;
  userLists: any = [];
  isListMode: boolean = false;
  isRemovingFromList: boolean = false;
  listId: any;

  showCopiedISRC: boolean = false;
  showCopiedISWC: boolean = false;

  pubLimitCount: number = 3;
  writerLimitCount: number = 3;

  constructor(
    private quansicService: QuansicService,
    private microState: MicroStateService,
    private listsService: UserListsService,
    private el: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private discoveryService: DiscoveryService,
    private clipboardUtility: ClipboardService,
    private mlcService: MlcServiceService,
    private zone: NgZone,
    private titleService: Title,
    private analyticsService: AnalyticsService,
    private songService: SongService,
  ) {
    this.titleService.setTitle('Song Identity - Song View');
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleEscapeKey(event: KeyboardEvent): void {
    // Check if the pressed key is "Escape" (key code 27)
    if (event.key === 'Escape') {
      window.history.back();
    }
  }

  ngOnInit() {
    this.getSpotifyToken();
    this.loadUserData();
    let loaded = false;
    this.route.paramMap.subscribe(params => {
      const listId = params.get('listId');
      if (listId) {
        this.listId = listId,
          this.isListMode = true;
      }
    });

    this.microState.stateData$.subscribe((data) => {
      if (data != null && !loaded) {
        loaded = true;
        this.discoveredDataISRC = data.discoveredDataISRC || null;
        this.discoveredDataISWC = data.discoveredDataISWC || null;
        this.trackData = data.discoveredTrackData || null;
        this.handleLoadTrackContent();
      }
    });
  };

  async loadUserData() {
    const auth = await this.getAuthState();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
      }
    });
  };

  async getAuthState() {
    return getAuth();
  };

  getSpotifyToken(): void {
    this.discoveryService.getSpotifyToken().subscribe((token) => {
      if (token) {
        this.spotifyToken = token;
      } else {
        console.error('Failed to retrieve Spotify token.');
      }
    });
  };

  handleLoadTrackContent() {
    if (this.trackData) {
      this.isLoadingTrack = false;
      this.animateInitialContent();
      if (this.trackData.previewURL) { this.isPreviewAvailable = true; };
      this.getAdditionalTrackData();
    } else {
      this.route.paramMap.subscribe(params => {
        const trackId = params.get('id1');
        if (trackId && this.spotifyToken) {
          this.discoveryService.getSpotifyTrackDataByTrackId(trackId, this.spotifyToken).subscribe((data) => {
            if (data !== null) {
              this.trackData = this.cleanSpotifySingleData(data);
              this.isLoadingTrack = false;
              this.animateInitialContent();
              this.getAdditionalTrackData();
            } else {
              console.log('Error getting data from spotify for track'); // TODO: Add error msg
            }
          })
        }
      });
    }
  }

  cleanSpotifySingleData(track) {
    const data = {
      title: track.name,
      trackSourceId: track.id,
      trackSourceURL: track?.external_urls?.spotify || null,
      isrc: track.external_ids.isrc || 'undefined',
      previewURL: track.preview_url,
      artist: track?.artists[0].name || 'undefined',
      artistImgURL: null,
      popularity: track.popularity || 0,
      album: track?.album?.name || 'undefined',
      albumArtURL: track.album?.images[1].url || null,
      copyright: 'undefined',
    };
    return data;
  }

  getAdditionalTrackData() {
    if (this.trackData.isrc) {
      this.getDataFromMLC();
      // this.quansicService.getDataFromISRC(this.trackData.isrc).subscribe((data) => {
      //   if (data) {
      //     const recordingData = this.cleanRecordingData(data.recording)
      //     const updatedTrackData = {
      //       ...this.trackData,
      //       recordingData: recordingData,
      //     }
      //     this.trackData = updatedTrackData;
      //     this.isLoadingISRC = false;
      //     if (data.recording.works) {
      //       this.getDiscoveredWorkData(data.recording.works);
      //     }
      //   } else {
      //     console.log('No Data Returned') // ADD ERROR
      //   }
      // });
    } else {
      alert('track does not have an ISRC') // ADD ERROR
    }
  };

  cleanRecordingData(recording) {
    let mainArtist = null
    if (recording?.contributors && recording.contributors.length > 0) {
      let firstArtist = recording.contributors.filter((r) => r.contributorType === 'MainArtist')
      mainArtist = firstArtist[0];
    }
    const recordingData = {
      mainArtist: mainArtist,
      pLine: recording?.pLine || null,
      linkedWorks: recording?.works || null,
      releaseDate: recording?.releaseDate || null,
    };
    return recordingData;
  };

  getDataFromMLC() {
    this.mlcService.getDataFromMLC(this.trackData.isrc, null).subscribe((data) => {
      if (data != null) {
        const mlcWorkData = data.mlcWorkData;
        const mlcLabels = data.labels || null;
        const updatedTrackData = {
          ...this.trackData,
          mlcWorkData: mlcWorkData,
          mlcLabels: mlcLabels,
        }
        this.trackData = updatedTrackData;
        this.isLoadingISRC = false;
        this.isLoadingISWC = false;
        this.handleSongAnalytics();
      }
    })
  };

  handleSongAnalytics() {
    if (!this.isListMode) {
      const workData = this.trackData.mlcWorkData;

      const hasLabel = this.trackData.mlcLabels && this.trackData.mlcLabels.length > 0;
      const hasPublishers = workData && workData.publishers && workData.publishers.length > 0;
      const hasISWC = workData && workData.iswc && workData.iswc.length > 0;
      const hasWriters = workData && workData.writers && workData.writers.length > 0;

      if (hasLabel && hasPublishers && hasISWC && hasPublishers && hasWriters) {
        this.analyticsService.captureEvent("song_quality_allData");
      } else if (!hasLabel && !hasPublishers && !hasISWC && !hasPublishers && !hasWriters) {
        this.analyticsService.captureEvent("song_quality_missingAllData");
        const trackDataToSave = {
          isrc: this.trackData.isrc,
          songTitle: this.trackData?.title || 'undefined',
          artistName: this.trackData.artist || 'undefined',
          source: 'spotify',
          sourceId: this.trackData.trackSourceId || 'undefined',
        }
        this.songService.handleIncompleteData(this.user.uid, trackDataToSave);
        this.isTrackMissingAllData = true;
      } else {
        const missingData = [];

        if (!hasLabel) {
          missingData.push("label");
        }
        if (!hasPublishers) {
          missingData.push("publishers");
        }
        if (!hasISWC) {
          missingData.push("ISWC");
        }
        if (!hasWriters) {
          missingData.push("writers");
        }

        const eventName = `song_quality_missing_${missingData.join("_")}`;
        this.analyticsService.captureEvent(eventName);
      }
    }
  }


  // getDiscoveredWorkData(works) {
  //   if (works.length > 1) {
  //     console.log('multiple works detected');
  //     this.quansicService.getDataFromISWC(works[0].iswc).subscribe((data) => {
  //       if (data) {
  //         console.log('quansic ISWC data', data);
  //         const workData = this.cleanWorksData(data.work);
  //         const updatedTrackData = {
  //           ...this.trackData,
  //           workData: workData,
  //         }
  //         this.trackData = updatedTrackData;
  //         console.log('final track data shape', this.trackData)
  //         this.isLoadingISWC = false;
  //         // this.discoveredDataISWC = data.work;
  //         // this.microState.setData('discoveredDataISWC', data.work); // TODO: Whittle down data
  //       } else {
  //         console.log('No Data Returned') // ADD ERROR
  //         this.isLoadingISWC = false;
  //       }
  //     });
  //   } else {
  //     this.quansicService.getDataFromISWC(works[0].iswc).subscribe((data) => {
  //       if (data) {
  //         console.log('quansic ISWC data', data);
  //         this.isLoadingISWC = false;
  //         const workData = this.cleanWorksData(data.work);
  //         const updatedTrackData = {
  //           ...this.trackData,
  //           workData: workData,
  //         }
  //         this.trackData = updatedTrackData;
  //         console.log('final track data shape', this.trackData)
  //         // this.discoveredDataISWC = data.work;
  //         // this.microState.setData('discoveredDataISWC', data.work); // TODO: Whittle down data
  //       } else {
  //         console.log('No Data Returned') // ADD ERROR
  //         this.isLoadingISWC = false;
  //       }
  //     });
  //   }
  // };

  cleanWorksData(work) {
    const seenContributors = new Set();
    const cleanContributors = (work?.contributors || []).filter(contributor => {
      const contributorName = contributor?.name?.toUpperCase();
      if (!seenContributors.has(contributorName)) {
        seenContributors.add(contributorName);
        return true;
      }
      return false;
    });
    const workData = {
      workTitle: work?.title || 'undefined',
      iswc: work?.iswc || 'undefined',
      songwriters: cleanContributors,
      linkedRecordings: work?.recordings || []
    };

    return workData;
  }

  onClickCheckSongView(iswc) {
    const url = `https://www.ascap.com/repertory#/ace/search/iswc/${iswc}`
    window.open(url, '_blank');
  };

  onClickShowSaveOptions() {
    this.isLoadingLists = true;
    this.listsService.getListsByUserId(this.user.uid).then((data) => {
      if (data) {
        this.userLists = data;
        if (data.length === 0) {
          this.newListName = 'My List';
          this.isNewList = true;
        };
        this.isLoadingLists = false;
        this.showListOverlay = true;
      } else {
        console.log('Error getting user lists'); // TODO: Add friendly error
        this.isLoadingLists = false;
      }
    })
  }

  handleListSelected(list) {
    this.selectedList = list;
  }

  handleEmptyStateListAction(name) {
    this.newListName = name;
    this.isNewList = true;
  }

  onClickSaveToList() {
    if (this.isNewList && this.newListName.length < 0) {
      console.log('List needs a name') // TODO: add error
      return;
    }

    this.isSavingToList = true;
    const trackDataPayload = {
      ...this.trackData,
      savedAt: new Date,
    }

    if (this.isNewList) {
      const newListData = {
        name: this.newListName,
        createdAt: new Date,
        updatedAt: new Date,
        referenceTiles: [this.trackData.albumArtURL]
      }
      this.listsService.createUserList(this.user.uid, newListData).then((data) => {
        if (data != null) {
          this.listsService.addSongToUserList(data, this.user.uid, trackDataPayload).then((data) => {
            if (data != null) {
              this.isSavingToList = false;
              this.showListOverlay = false;
              this.analyticsService.captureEvent("song_saved")
            }
          }).catch((e) => {
            console.log('save song to list error', e); // TODO: ADD ERRORS HERE AND BELOW
          });
        }
      }).catch((e) => {
        console.log('create list error', e);
      });
    } else {
      const newTiles = [...new Set([...this.selectedList.referenceTiles, this.trackData.albumArtURL])];
      const cleanedTiles = newTiles.slice(-4);
      const listUpdate = {
        ...this.selectedList,
        updatedAt: new Date(),
        referenceTiles: cleanedTiles,
      }
      this.listsService.updateUserList(this.user.uid, listUpdate).then(() => {
        this.listsService.addSongToUserList(listUpdate.id, this.user.uid, trackDataPayload).then((data) => {
          if (data != null) {
            this.isSavingToList = false;
            this.showListOverlay = false;
            this.analyticsService.captureEvent("song_saved")
          }
        }).catch((e) => {
          console.log('save song to list error', e); // TODO: ADD ERRORS HERE AND BELOW
        });
      }).catch((e) => {
        console.log('create list error', e);
      })
    }
  }

  onClickCloseSongView() {
    if (this.isListMode) {
      window.history.back();
    } else {
      this.router.navigate(['dashboard']);
    }
  }

  animateInitialContent() {
    setTimeout(() => {
      const trackCardLoadAnimation = anime.timeline({ autoplay: false, loop: false });
      const trackCard = this.el.nativeElement.querySelector('#track-card');

      trackCardLoadAnimation
        .add({
          targets: trackCard,
          scale: [1.05, 1],
          opacity: [0, 1],
          translateY: [-40, 0],
          easing: 'easeOutCubic',
          duration: 900,
        });
      trackCardLoadAnimation.play();
    }, 600);
  };

  copyToClipboard(type: string, value: string): void {
    this.clipboardUtility.copy(value);

    switch (type) {
      case 'iswc':
        this.showCopiedISWC = true;
        break;
      case 'isrc':
        this.showCopiedISRC = true;
        break;
    }

    setTimeout(() => {
      this.zone.run(() => {
        this.showCopiedISRC = false;
        this.showCopiedISWC = false;
      });
    }, 2300);
  }

  // DATA OUT UTILITIES

}
