import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, updateProfile, sendEmailVerification, deleteUser, signOut } from "firebase/auth";
import { SubMonitorService } from 'src/app/services/sub-monitor.service';
import { FreeTier, TrialTier, SubscriptionTiers, NoSubscription, BetaTier } from 'src/app/constants/subscription-tiers';
import {
  getFirestore,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import anime from 'animejs/lib/anime.es';
import { MicroStateService } from 'src/app/services/micro-state.service';
import { Title } from '@angular/platform-browser';


export interface ImgFile {
  name: string;
  filepath: string;
  size: number;
}

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  userId: any;
  user: any;
  userSubscription: any;
  subscriptionDetail: any;
  isLoading = false;
  accountDisplayName: any;
  verifyEmailSent = false;
  errorString: any;
  isPreparingPortal = false;
  userProfile: any;
  updateMessage: any = null;

  showDeleteWarning = false;
  showDeleteConfirmation = false;
  isDeletingUser = false;
  authenticationRequired = false;

  storage: any;
  database: any;
  fileUploadProgress: any;
  isFileUploading: boolean;
  isFileUploaded: boolean;
  imgIXurlPrefix = 'https://********.imgix.net/';  // TO DO SETUP IMG IX
  fileUrl: any;
  processedFileName: any;
  isSaving = false;
  customerPortal: any;



  constructor(
    private subMonitor: SubMonitorService,
    private router: Router,
    private userService: UserService,
    private el: ElementRef,
    private microState: MicroStateService,
    private titleService: Title,
  ) {
    this.titleService.setTitle('Song Identity - Account');
  }

  ngOnInit(): void {
    const auth = getAuth();
    this.database = getFirestore()
    this.user = auth.currentUser;
    this.accountDisplayName = this.user.displayName;
    this.microState.stateData$.subscribe((data) => {
      if (data != null) {
        this.userProfile = data.userProfile;
      }
    });

    this.storage = getStorage();

    this.subMonitor.getSubscription().subscribe((data) => {
      this.userSubscription = data;
      if (this.userSubscription === 'grandFather') { this.subscriptionDetail = FreeTier };
      if (this.userSubscription === 'trial') { this.subscriptionDetail = TrialTier };
      if (this.userSubscription === 'beta') { this.subscriptionDetail = BetaTier };
      if (this.userSubscription === 'subMonthly') { this.subscriptionDetail = SubscriptionTiers[0] };
      if (this.userSubscription === 'subYearly') { this.subscriptionDetail = SubscriptionTiers[1] };
      if (this.userSubscription === 'expired') { this.subscriptionDetail = NoSubscription };
    });

    this.animatePage();
  }

  onSelectPhoto() {
    document.getElementById('photo-input').click();
  }

  uploadImage(event: FileList) {
    this.isFileUploading = true;

    const file = event.item(0);

    if (file.type.split('/')[0] !== 'image') {
      console.log('File type is not supported!');
      return;
    }

    const filePath = `fileStorage/${new Date().getTime()}_${file.name}`;
    const imgIxPath = `${new Date().getTime()}_${file.name}`;
    const storageRef = ref(this.storage, filePath);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {
        this.fileUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      },
      (error) => {
        console.log('error with upload' + error)
        this.isFileUploading = false;
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          this.fileUrl = downloadURL;
          updateProfile(this.user, {
            photoURL: this.fileUrl
          });
          this.userService.updateUserPublicProfile({photoURL: this.fileUrl, id: this.user.uid})
          .then(() => {
            this.refreshProfileData();
            this.isFileUploading = false;
          })
          .catch((e) => {
            console.log(e);
          })
        });
      }
    );
  }

  refreshProfileData() {
    this.userService.getUserPublicProfileById(this.user.uid).then((data) => {
      if (data != null) {
        this.userProfile = data;
        this.microState.setData('userProfile', data);
      }
    })
  }

  onSaveProfileChanges() {
    updateProfile(this.user, {
      displayName: this.accountDisplayName
    })
      .then(() => {
        this.userService.updateUserPublicProfile({displayName: this.accountDisplayName, id: this.user.uid})
          .then(() => {
            this.refreshProfileData();
            this.updateMessage = "Profile Updated";
            setTimeout(() => {
              this.updateMessage = null;
            }, 3000);
          })
          .catch((e) => {
            console.log(e);
          })
      })
      .catch((error) => { console.log(error) });
  }

  onViewSubscription() {
    this.router.navigate(['subscribe']);
  }

  async onManageSubscription() {
    this.isPreparingPortal = true;
    const functions = getFunctions();
    const getPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
    getPortalLink(this.user.uid)
      .then((portal) => {
        this.customerPortal = portal.data;
        window.location.assign(this.customerPortal.url)
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onClickDeleteAccount() {
    this.showDeleteWarning = true;
  }

  onClickDeleteWarning() {
    this.showDeleteConfirmation = true;
  }

  onCancelDeleteWarning() {
    this.showDeleteConfirmation = false;
    this.showDeleteWarning = false;
  }

  onClickDeleteConfirmation() {
    this.showDeleteConfirmation = false;
    this.showDeleteWarning = false;
    this.isDeletingUser = true;
    deleteUser(this.user).then(() => {
      this.router.navigate(['/login'])
    }).catch((error) => {
      this.isDeletingUser = false;
      console.log(error.message);
      if (error.message == 'Firebase: Error (auth/requires-recent-login).') {
        this.authenticationRequired = true;
      }
    });
  }

  onReAuth() {
    const auth = getAuth();
    signOut(auth);
  }


  resendVerificationEmail() {
    sendEmailVerification(this.user)
      .then(() => {
        this.verifyEmailSent = true;
        this.updateMessage = "Check Your Email";
        setTimeout(() => {
          this.updateMessage = null;
        }, 3000);
      });
  }

  animatePage() {
    const animation = anime.timeline({ autoplay: false, loop: false });

    setTimeout(() => {
      animation
      .add({
        targets: '.ss-account-card',
        opacity: [0, 1],
        translateY: [32, 0],
        easing: 'easeOutElastic(3, .7)',
        duration: 600,
        delay: anime.stagger(150),
      })
      .add({
        targets: '.ss-account-header',
        opacity: [0, 1],
        translateY: [32, 0],
        easing: 'easeOutCubic',
        duration: 1000,
      }, '-=400');

      animation.play();
    }, 300);

  };

  onClickChromeExtension() {
    window.open('https://chromewebstore.google.com/detail/song-identity-by-switchch/kdfcjiffgbljimmgpihfafncfnbbaakl?hl=en&authuser=0', '_blank');
  }

  onClickAppleBtn() {
    window.open('https://apps.apple.com/us/app/song-identity/id6478509552', '_blank');
  }

}
