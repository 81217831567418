import { Component, OnInit } from '@angular/core';
import { SubscriptionTiers } from 'src/app/constants/subscription-tiers';
import {
  collection,
  getFirestore,
  doc,
  getDoc,
  query,
  where,
  getDocsFromServer,
  addDoc,
  deleteDoc,
  updateDoc,
  onSnapshot
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics, logEvent } from "firebase/analytics";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {
  database: any;
  products = SubscriptionTiers;
  user: any;
  isPreparingStore = false;
  analytics= getAnalytics();

  constructor(
    private titleService: Title,
  ) {
    this.titleService.setTitle('Song Identity - Account');
  }

  ngOnInit(): void {
    const auth = getAuth();
    this.user = auth.currentUser;
    this.database = getFirestore();
    logEvent(this.analytics, 'screen_view', {
      firebase_screen: 'SUBSCRIBE',
      firebase_screen_class: 'customViewLog'
    });
  }

  async subscribe(product) {
    logEvent(this.analytics, 'select_content', {
      content_type: 'SUBSCRIPTION_SELECTED',
      content_id: 'TIER - ' + product.name
    });
    product.isProcessing = true;
    this.isPreparingStore = true;
    const docRef = await addDoc(collection(this.database, `customers/${this.user.uid}/checkout_sessions`), {
      price: product.priceCode,
      success_url: window.location.origin + '/processing',
      cancel_url: window.location.href,
      allow_promotion_codes: true,
    });

    onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data();
      if (error) {
        this.isPreparingStore = false;
        alert(`An error: ${error.message}`);
      }
      if (url) {
        window.location.assign(url);
      }
    })
  }

}
