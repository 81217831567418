import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SPOTIFY_URLS, TIDAL_URLS, APPLE_URLS } from '../constants/api-urls';


@Injectable({
  providedIn: 'root'
})
export class DiscoveryService {

  constructor(private http: HttpClient) {}

  getSpotifyToken(): Observable<string | null> {
    const storedToken = localStorage.getItem('spotifyToken');
    const storedExpiration = localStorage.getItem('spotifyTokenExpiration');
    
    if (storedToken && storedExpiration && +storedExpiration > Date.now()) {
      return of(storedToken);
    } else {
      return this.http.get<{ token: string }>(SPOTIFY_URLS.spotifyTokenURL).pipe(
        map((response) => {
          if (response && response.token) {
            const spotifyToken = response.token;

            // Store the token in local storage with its expiration time
            const minutesAllowed = 45 * 60 * 1000;
            const expirationTime = Date.now() + minutesAllowed;
            localStorage.setItem('spotifyToken', spotifyToken);
            localStorage.setItem('spotifyTokenExpiration', expirationTime.toString());
            return spotifyToken;
          } else {
            console.error('Failed to retrieve or store Spotify token.');
            return null;
          }
        }),
        catchError((error) => {
          console.error('Error getting Spotify token:', error);
          return of(null);
        })
      );
    }
  }

  getSpotifyTrackDataFromUrl(url: string, token: string): Observable<any> {
    const trackId = this.extractTrackIdFromUrl(url);
    if (trackId) {
      const requestBody = {
        trackId,
        token,
      };

      return this.http.post<any>(SPOTIFY_URLS.spotifyTrackURL, requestBody);
    } else {
      throw new Error('Invalid Spotify URL');
    }
  }

  private extractTrackIdFromUrl(url: string): string | null {
    // Extract the track ID from the Spotify URL
    const match = url.match(/\/track\/(\w+)/);
    return match ? match[1] : null;
  }

  getSpotifyTrackDataFromSearch(string: string, token: string): Observable<any> {
    if (string) {
      const requestBody = {
        string,
        token,
      };

      return this.http.post<any>(SPOTIFY_URLS.spotifySearchURL, requestBody);
    } else {
      throw new Error('Invalid Spotify URL');
    }
  }

  getSpotifyTrackDataByTrackId(trackId: string, token: string): Observable<any> {
    if (trackId) {
      const requestBody = {
        trackId,
        token,
      };
      return this.http.post<any>(SPOTIFY_URLS.spotifyTrackURL, requestBody);
    } else {
      throw new Error('No Track Id Provided');
    }
  }

  getAppleToken(): Observable<string | null> {
    const storedToken = localStorage.getItem('appleToken');
    const storedExpiration = localStorage.getItem('appleTokenExpiration');
    
    if (storedToken && storedExpiration && +storedExpiration > Date.now()) {
      return of(storedToken);
    } else {
      return this.http.get<{ token: string }>(APPLE_URLS.getAppleToken).pipe(
        map((response) => {
          if (response && response.token) {
            const spotifyToken = response.token;

            // Store the token in local storage with its expiration time
            const minutesAllowed = 7 * 24 * 60 * 60 * 1000;
            const expirationTime = Date.now() + minutesAllowed;
            localStorage.setItem('appleToken', spotifyToken);
            localStorage.setItem('appleTokenExpiration', expirationTime.toString()); // TODO: Fix naming here
            return spotifyToken;
          } else {
            console.error('Failed to retrieve or store Apple token.');
            return null;
          }
        }),
        catchError((error) => {
          console.error('Error getting Apple token:', error);
          return of(null);
        })
      );
    }
  };

  getAppleTrackByAppleId(appleMusicId: string, token: string): Observable<any> {
    if (appleMusicId) {
      const requestBody = {
        appleMusicId,
        token,
      };
      return this.http.post<any>(APPLE_URLS.getDataFromAppleId, requestBody);
    } else {
      throw new Error('No Track Id Provided');
    }
  }

  // TIDAL 
  getTidalToken(): Observable<string | null> {
    const storedToken = localStorage.getItem('tidalToken');
    const storedExpiration = localStorage.getItem('tidalTokenExpiration');

    if (storedToken && storedExpiration && +storedExpiration > Date.now()) {
      return of(storedToken);
    } else {
      return this.http.get<{ token: string }>(TIDAL_URLS.tidalTokenURL).pipe(
        map((response) => {
          if (response && response.token) {
            const tidalToken = response.token;

            // Store the token in local storage with its expiration time
            const minutesAllowed = 50 * 60 * 1000;
            const expirationTime = Date.now() + minutesAllowed;
            localStorage.setItem('tidalToken', tidalToken);
            localStorage.setItem('tidalTokenExpiration', expirationTime.toString());
            return tidalToken;
          } else {
            console.error('Failed to retrieve or store TIDAL token.');
            return null;
          }
        }),
        catchError((error) => {
          console.error('Error getting TIDAL token:', error);
          return of(null);
        })
      );
    }
  };

  getTidalDataFromSearch(string: string, token: string): Observable<any> {
    if (string) {
      const requestBody = {
        string,
        token,
      };
      return this.http.post<any>(TIDAL_URLS.tidalSearchURL, requestBody);
    } else {
      throw new Error('Invalid Spotify URL');
    }
  }
}
