import { Injectable } from '@angular/core';
import {
  collection,
  getFirestore,
  doc,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { Observable } from 'rxjs';

export interface Educations {
  id?: string;
  onboarding: boolean;
}

export interface PublicProfile {
  displayName: string;
  photoURL: string;
  grandfathered?: boolean;
  signUpDate?: any;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  database = getFirestore();

  constructor() { }

  async getUserEducationsById(id) {
    const docRef = doc(this.database, `user-educations/${id}`);  
    const docSnap = await getDoc(docRef);
    return docSnap.data() as Observable<Educations>;
  }

  createUserEducations(education: Educations, userId) {
    return setDoc(doc(this.database, 'user-educations', userId), education);
  }

  deleteUserEducations(educations: Educations) {
    const docRef = doc(this.database, `user-educations/${educations.id}`);
    return deleteDoc(docRef);
  }

  updateUserEducations(educationData) {
    const docRef = doc(this.database, `user-educations/${educationData.id}`); 
    return updateDoc(docRef, educationData);
  }

  async getUserPublicProfileById(id) {
    const profileRef = doc(this.database, `user-profiles/${id}`);  
    const profileSnap = await getDoc(profileRef);
    return profileSnap.data();
  }

  createUserPublicProfile(profile: PublicProfile, userId) {
    return setDoc(doc(this.database, 'user-profiles', userId), profile);
  }

  updateUserPublicProfile(profileData) {
    const docRef = doc(this.database, `user-profiles/${profileData.id}`); 
    return updateDoc(docRef, profileData);
  }

  createUserAccessRequest(requestInfo) {
    console.log(requestInfo);
    const accessRequestsCollectionRef = collection(this.database, 'access-requests');
    return addDoc(accessRequestsCollectionRef, requestInfo);
  }

}
