<div class="ss-account-header-wrapper">
    <div class="ss-account-header" *ngIf="!isDeletingUser">
        Account
    </div>
    <div class="ss-account-header" *ngIf="isDeletingUser">
        Good Bye...
    </div>
</div>

<div class="ss-account-content" *ngIf="user && !isDeletingUser">

    <div class="ss-account-card">
        <div *ngIf="isLoading" class="account-loader">
            <div class="ss-spinner"></div>
        </div>

        <div class="account-detail">

            <div class="account-avatar">
                <img class="avatar-img" *ngIf="userProfile?.photoURL" [src]="userProfile?.photoURL">
                <img *ngIf="!userProfile?.photoURL" src="/assets/images/img_placeholder.png" height="100%" width="100%" />
            </div>
            <circle-progress *ngIf="isFileUploading && fileUploadProgress" [percent]="fileUploadProgress" [radius]="64"
                [outerStrokeWidth]="3" [innerStrokeWidth]="0" [outerStrokeColor]="'#EB5216'"
                [innerStrokeColor]="'#EB5216'" [animation]="false" [showTitle]="false" [showSubtitle]="false"
                [showUnits]="false">
            </circle-progress>
            <div *ngIf="!isFileUploading" class="avatar-edit-chip" (click)="onSelectPhoto()">
                <svg-icon src="/assets/icons/camera.svg"></svg-icon>
            </div>
            <input class="avatar-input" id="photo-input" type="file" (change)="uploadImage($event.target.files)"
                style="transform: translate(32px, -36px);" hidden />
            <div class="update-message" *ngIf="updateMessage">
                {{updateMessage}}
            </div>
            <div class="upload-progress" *ngIf="fileUploadProgress && isFileUploading">{{fileUploadProgress | number:
                '1.0-0'}}%</div>
            <div class="ss-form-group">
                <input class="ss-input expanded " type="text" autocomplete="off" placeholder="Your Name"
                    [(ngModel)]="accountDisplayName" maxlength="40" />
            </div>

            <div class="ss-form-group">
                <input class="ss-input expanded " type="email" autocomplete="off" placeholder="you@something.com"
                    disabled autocomplete="username" [(ngModel)]="user.email" />
            </div>

            <div class="email-verify-wrapper" *ngIf="user">
                <div class="verified-email" *ngIf="user.emailVerified">
                    Email Verified <span *ngIf="user.providerData && user.providerData[0].providerId !== 'password'">by
                        Provider: {{user.providerData[0].providerId}}</span>
                </div>
                <div class="non-verified-email" *ngIf="!user.emailVerified && !verifyEmailSent">
                    Email Not Verified - <a (click)="resendVerificationEmail()">Resend Email</a>
                </div>
            </div>

            <button *ngIf="(accountDisplayName !== user.displayName && accountDisplayName.length > 1)"
                class="ss-primary-btn mt32" (click)="onSaveProfileChanges()">Save Changes</button>
        </div>
    </div>

    <div class="downloads-row">

        <div class="ss-account-card">
            <div class="info">
                <div class="title">Chrome Extension</div>
                <div class="msg">Get instant results for songs playing in the browser</div>
            </div>
            <div class="btn-wrapper">
                <div class="download-btn" (click)="onClickChromeExtension()">
                    <svg-icon [svgStyle]="{ 'height.px':24 }" src="/assets/icons/download-extension.svg"></svg-icon>
                </div>
            </div>
        </div>

        <div class="ss-account-card">
            <div class="info">
                <div class="title">iOS Companion App</div>
                <div class="msg">Use the power of Shazam to detect and pull ownership info</div>
            </div>
            <div class="btn-wrapper">
                <div class="apple-btn" (click)="onClickAppleBtn()">
                    <svg-icon [svgStyle]="{ 'height.px':44 }" src="/assets/images/apple-btn.svg"></svg-icon>
                </div>
            </div>
        </div>

    </div>

    <div class="ss-account-card" style="min-height: 408px;">
        <div class="subscription-detail">

            <div class="subscription-info-wrapper">
                <div *ngIf="subscriptionDetail" class="subscription-label">Your Subscription</div>

                <div class="ss-subscription-widget" *ngIf="subscriptionDetail">
                    <div class="header">
                        <div class="tier">
                            {{subscriptionDetail.name}}
                        </div>
                        <div class="price">
                            {{subscriptionDetail.price}}
                        </div>
                    </div>

                    <div class="description">
                        {{subscriptionDetail.description}}
                    </div>

                    <div class="benefits">
                        <div class="feature" *ngFor="let b of subscriptionDetail.benefits">- {{b}}</div>
                    </div>

                    <button *ngIf="subscriptionDetail.showUpgrade" class="ss-secondary-btn mt32"
                        (click)="onViewSubscription()">View Options</button>
                    <button *ngIf="subscriptionDetail.showManage" class="ss-secondary-btn mt32"
                        (click)="onManageSubscription()" [disabled]="isPreparingPortal">
                        <span *ngIf="!isPreparingPortal">Manage Subscription</span>
                        <div class="ss-spinner" *ngIf="isPreparingPortal"></div>
                    </button>


                </div>
            </div>

        </div>

    </div>

    <!-- <div class="account-links">
            <div class="links-row">
                <div class="link-label">Need Help</div>
                <a class="link" href="mailto:info&#64;switchchord.com?subject=Hey%20It's%20Me&body=What%20can%20we%20help%20ya%20with%3F%20%20If%20you%20are%20needing%20help%20with%20your%20account%2C%20make%20sure%20to%20give%20us%20your%20high%20level%20account%20info!%20Cheers%0A%0A-Team%20Switchchord">
                    Contact Us
                </a>
            </div>
    
            <div class="links-row">
                <div class="link-label">Have an idea or feedback?</div>
                <a class="link" href="mailto:info&#64;switchchord.com?subject=Feedback%20is%20Like%20Water&body=It%20makes%20things%20grow%20and%20we%20don't%20get%20enough%20of%20it%20each%20day!%20Let%20us%20know%20what%20is%20on%20your%20mind.%20%0A%0A-Team%20Switchchord">
                    Bring it on!
                </a>
            </div>
    
            <div class="links-row">
                <div class="link-label">Had Enough?</div>
                <a *ngIf="!showDeleteWarning && !showDeleteConfirmation && !isDeletingUser" class="link red" (click)="onClickDeleteAccount()">Delete My Account</a>
                <a *ngIf="showDeleteWarning && !showDeleteConfirmation && !isDeletingUser" class="link red" (click)="onClickDeleteWarning()">If you really mean it, click me again.</a>
                <a *ngIf="!showDeleteWarning && showDeleteConfirmation && !isDeletingUser" class="link red" (click)="onClickDeleteConfirmation()">This click will delete your account right now!</a>
            </div>
        </div> -->

    <div class="ss-modal-bg" *ngIf="authenticationRequired">
        <div class="modal-container">
            <div class="message mb16">
                For security reasons, we need you to authenticate once more before this account can be deleted. Then you
                will be able to remove the account.
            </div>

            <a class="link" (click)="onReAuth()">Re-Authenticate</a>
        </div>
    </div>

    <div class="ss-modal-bg danger" *ngIf="showDeleteWarning && !isDeletingUser">
        <div class="destructive-confirm" *ngIf="showDeleteWarning && !showDeleteConfirmation && !isDeletingUser">
            <div class="warning-header">Woah There...</div>
            <div class="warning-text">
                You are about to delete your account and all the beautiful things within it...
            </div>
            <button class="ss-primary-btn warning mt32" (click)="onClickDeleteWarning()">Yep, Still Want To</button>
            <div class="cancel-text" (click)="onCancelDeleteWarning()">Cancel</div>
        </div>
        <div class="destructive-confirm" *ngIf="showDeleteWarning && showDeleteConfirmation && !isDeletingUser">
            <div class="warning-header">I can't watch...</div>
            <div class="warning-text">
                Pressing this button will immediately sign you out and remove your account from Song Identity.
            </div>
            <button class="ss-primary-btn warning mt32" (click)="onClickDeleteConfirmation()">Totally Still Want
                To</button>
            <div class="cancel-text" (click)="onCancelDeleteWarning()">Cancel</div>
        </div>
    </div>
</div>