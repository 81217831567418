import { Injectable } from '@angular/core';
import {
  collection,
  getFirestore,
  doc,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class SongService {
  database = getFirestore();

  constructor() { }

  handleIncompleteData(userId, trackData) {
    const docId = trackData.isrc;
    const docRef = doc(this.database, `bad-tracks/${docId}`);

    getDoc(docRef).then((docSnapshot) => {
      if (docSnapshot.exists()) {
        const badTrackData = docSnapshot.data();
        this.updateBadTrackRecord(userId, badTrackData);
        console.log('track record found, updating')
      } else {
        this.createBadTrackRecord(userId, trackData);
        console.log('track record not found, updating')
      }
    }).catch((error) => {
      console.error("Error getting document:", error);
    });
  };

  createBadTrackRecord(userId, trackData) {
    const trackRecord = {
      isrc: trackData.isrc,
      createdAt: new Date(),
      updatedAt: new Date(),
      songTitle: trackData.songTitle,
      artistName: trackData.artistName,
      source: trackData.source,
      sourceId: trackData.sourceId,
      viewCount: 1,
      userIds: [userId]
    };
    setDoc(doc(this.database, 'bad-tracks', trackData.isrc), trackRecord).catch((error) => {
      console.error("Error creating bad track record:", error);
    });
  };

  updateBadTrackRecord(userId, badTrackData) {
    const userIdMatch = badTrackData.userIds.some((id) => id === userId);

    let trackUpdate = {};

    if (userIdMatch) {
      trackUpdate = {
        ...badTrackData,
        viewCount: badTrackData.viewCount + 1,
        updatedAt: new Date(),
      }
    } else {
      const userIds = badTrackData.userIds;
      userIds.push(userId);
      trackUpdate = {
        ...badTrackData,
        viewCount: badTrackData.viewCount + 1,
        updatedAt: new Date(),
        userIds: userIds,
      }
    }

    const docRef = doc(this.database, `bad-tracks/${badTrackData.isrc}`);
    updateDoc(docRef, trackUpdate).catch((error) => {
      console.error("Error updating bad track record:", error);
    });
  };

}
