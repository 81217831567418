<div class="ss-page-wrapper auth">
    <div *ngIf="isProcessing" class="ss-spinner page"></div>

    <div *ngIf="!isSubmitted && !isProcessing" id="card" class="ss-auth-content ss-modal-card">
        <div id="header" class="ss-auth-header mb24">
        Request Beta Access
        </div>
        <div class="ss-auth-form">
          <form class="form" [formGroup]="userForm"
            (ngSubmit)="onClickSubmit(userForm.value.name, userForm.value.email, userForm.value.organization)">

            <div class="ss-form-group">
              <input id="name" class="ss-input expanded " type="text" autocomplete="name" formControlName="name"
                placeholder="Your Name" (focus)="onFieldIn()" />
            </div>

            <div class="ss-form-group">
              <input id="email" class="ss-input expanded " type="email" autocomplete="off" formControlName="email"
                placeholder="you@something.com" (focus)="onFieldIn()" autocomplete="username" />
            </div>

            <div class="ss-form-group">
              <input id="org" class="ss-input expanded " type="text" formControlName="organization"
                placeholder="Organization" autocomplete="off" (focus)="onFieldIn()"/>
            </div>

            <ng-container *ngFor="let error of errorMsgs.email">
              <app-message-prompt *ngIf="userForm.get('email').hasError(error.type) && userForm.get('email').touched"
                type="warning" mb="24px" messageTitle="" messageBody="{{ error.message }}">
              </app-message-prompt>
            </ng-container>


            <app-message-prompt *ngIf="errorMsg" type="danger auth" mb="24px" messageTitle="Nope. Try Again."
              messageBody="{{ errorMsg }}">
            </app-message-prompt>

            <button id="sign-up-button" class="ss-primary-btn mt32" [disabled]="!userForm.valid || isProcessing">Submit</button>

          </form>
        </div>

      </div>


      <div *ngIf="isSubmitted && !isProcessing" id="card" class="ss-auth-content ss-modal-card">
        <div id="header" class="ss-auth-header mb24">
            Request Submitted
        </div>

        <div class="submitted-copy">
            Thanks! We will email you when we issue another batch of access codes or when the public beta opens.
        </div>
    </div>

</div>