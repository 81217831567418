import { Component, ElementRef, HostListener, Input, NgZone, ViewChild } from '@angular/core';
import anime from 'animejs/lib/anime.es';
import { QuansicService } from 'src/app/services/quansic.service';
import { MicroStateService } from 'src/app/services/micro-state.service';
import { UserListsService } from 'src/app/services/user-lists.service';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { DiscoveryService } from 'src/app/services/discovery.service';
import { ClipboardService } from 'ngx-clipboard';
import { MlcServiceService } from 'src/app/services/mlc-service.service';

@Component({
  selector: 'app-listen',
  templateUrl: './listen.component.html',
  styleUrl: './listen.component.scss'
})
export class ListenComponent {

  trackData: any;
  user: any;

  isLoadingTrack: boolean = true;
  isLoadingISRC: boolean = true;
  isLoadingISWC: boolean = true;

  isPreviewAvailable: boolean = false;

  appleToken: any;
  appleId: any;

  discoveredDataISRC: any;
  discoveredDataISWC: any;

  showListOverlay: boolean = false;
  isLoadingLists: boolean = false;
  isNewList: boolean = false;
  newListName: string = '';

  selectedList: any;
  isSavingToList: boolean = false;
  userLists: any = [];
  isListMode: boolean = false;
  isRemovingFromList: boolean = false;
  listId: any;

  showCopiedISRC: boolean = false;
  showCopiedISWC: boolean = false;

  pubLimitCount: number = 3;
  writerLimitCount: number = 3;

  constructor(
    private discoveryService: DiscoveryService,
    private quansicService: QuansicService,
    private route: ActivatedRoute,
    private router: Router,
    private microState: MicroStateService,
    private listsService: UserListsService,
    private el: ElementRef,
    private clipboardUtility: ClipboardService,
    private mlcService: MlcServiceService,
    private zone: NgZone

  ) { }

  ngOnInit() {
    this.loadUserData();
    this.route.paramMap.subscribe(params => {
       this.appleId = params.get('appleId');
       if (this.appleId) {
        this.discoveryService.getAppleToken().subscribe((token) => {
          if (token) {
            this.appleToken = token;
            this.handleLoadTrackContent();
          } else {
            console.error('Failed to retrieve Apple token.');
          }
        });
       }
    });
  };

  async loadUserData() {
    const auth = await this.getAuthState();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
      }
    });
  };

  async getAuthState() {
    return getAuth();
  };

  handleLoadTrackContent() {
    this.discoveryService.getAppleTrackByAppleId(this.appleId, this.appleToken).subscribe((res) => {
      if (res != null) {
        console.log('APPLE DATA', res.data[0]);
        const trackData = this.cleanAppleMusicData(res.data[0]);
        this.trackData = trackData;
        this.isLoadingTrack = false;
        this.getAdditionalTrackData();
        this.animateInitialContent();
        if (this.trackData.previewURL) { this.isPreviewAvailable = true; };
      }
    });
  }

  cleanAppleMusicData(track) {
    const data = {
      title: track.attributes.name,
      trackSourceId: track.id,
      isrc: track.attributes.isrc || 'undefined',
      previewURL: track.attributes.previews[0].url || null,
      artist: track.attributes.artistName,
      artistImgURL: null,
      popularity: 0,
      album: track?.attributes.albumName || 'undefined',
      albumArtURL: this.getResizedAlbumArtUrl(track.attributes.artwork.url, 400, 400) || null,
      copyright: 'undefined',
    };
    return data;
  }

  getResizedAlbumArtUrl(originalUrl: string, width: number, height: number): string {
    // Replace placeholders {w} and {h} with the specified width and height
    const resizedUrl = originalUrl.replace('{w}', width.toString()).replace('{h}', height.toString());
    return resizedUrl;
  }

  getAdditionalTrackData() {
    if (this.trackData.isrc) {
      this.getDataFromMLC();
      // this.quansicService.getDataFromISRC(this.trackData.isrc).subscribe((data) => {
      //   if (data) {
      //     const recordingData = this.cleanRecordingData(data.recording)
      //     const updatedTrackData = {
      //       ...this.trackData,
      //       recordingData: recordingData,
      //     }
      //     this.trackData = updatedTrackData;
      //     this.isLoadingISRC = false;
      //     if (data.recording.works) {
      //       this.getDiscoveredWorkData(data.recording.works);
      //     } else {
      //       this.getMlcDataFromISWC();
      //     }
      //   } else {
      //     console.log('No Data Returned') // ADD ERROR
      //     this.getMlcDataFromISRC();
      //   }
      // });
    } else {
      alert('track does not have an ISRC') // ADD ERROR
    }
  };

  cleanRecordingData(recording) {
    let mainArtist = null
    if (recording?.contributors && recording.contributors.length > 0) {
      let firstArtist = recording.contributors.filter((r) => r.contributorType === 'MainArtist')
      mainArtist = firstArtist[0];
    }
    const recordingData = {
      mainArtist: mainArtist,
      pLine: recording?.pLine || null,
      linkedWorks: recording?.works || null,
      releaseDate: recording?.releaseDate || null,
    };
    return recordingData;
  };

  getDataFromMLC() {
    this.mlcService.getDataFromMLC(this.trackData.isrc, null).subscribe((data) => {
      if (data != null) {
        console.log('MLC DATA RETURNED', data);
        const mlcWorkData = data.mlcWorkData;
        const mlcLabels = data.labels || null;
        const updatedTrackData = {
          ...this.trackData,
          mlcWorkData: mlcWorkData,
          mlcLabels: mlcLabels,
        }
        this.trackData = updatedTrackData;
        this.isLoadingISRC = false;
        this.isLoadingISWC = false;
      }
    })
  };

  // getDiscoveredWorkData(works) {
  //   if (works.length > 1) {
  //     console.log('multiple works detected');
  //     this.quansicService.getDataFromISWC(works[0].iswc).subscribe((data) => {
  //       if (data) {
  //         console.log('quansic ISWC data', data);
  //         const workData = this.cleanWorksData(data.work);
  //         const updatedTrackData = {
  //           ...this.trackData,
  //           workData: workData,
  //         }
  //         this.trackData = updatedTrackData;
  //         console.log('final track data shape', this.trackData)
  //         this.isLoadingISWC = false;
  //         // this.discoveredDataISWC = data.work;
  //         // this.microState.setData('discoveredDataISWC', data.work); // TODO: Whittle down data
  //       } else {
  //         console.log('No Data Returned') // ADD ERROR
  //         this.isLoadingISWC = false;
  //       }
  //     });
  //   } else {
  //     this.quansicService.getDataFromISWC(works[0].iswc).subscribe((data) => {
  //       if (data) {
  //         console.log('quansic ISWC data', data);
  //         this.isLoadingISWC = false;
  //         const workData = this.cleanWorksData(data.work);
  //         const updatedTrackData = {
  //           ...this.trackData,
  //           workData: workData,
  //         }
  //         this.trackData = updatedTrackData;
  //         console.log('final track data shape', this.trackData)
  //         // this.discoveredDataISWC = data.work;
  //         // this.microState.setData('discoveredDataISWC', data.work); // TODO: Whittle down data
  //       } else {
  //         console.log('No Data Returned') // ADD ERROR
  //         this.isLoadingISWC = false;
  //       }
  //     });
  //   }
  // };

  cleanWorksData(work) {
    const seenContributors = new Set();
    const cleanContributors = (work?.contributors || []).filter(contributor => {
      const contributorName = contributor?.name?.toUpperCase();
      if (!seenContributors.has(contributorName)) {
        seenContributors.add(contributorName);
        return true;
      }
      return false;
    });
    const workData = {
      workTitle: work?.title || 'undefined',
      iswc: work?.iswc || 'undefined',
      songwriters: cleanContributors,
      linkedRecordings: work?.recordings || []
    };
  
    return workData;
  }

  getMlcDataFromISRC() {
    this.isLoadingISRC = false;
    this.isLoadingISWC = false;
    // function should hit the new endpoint and bring back all the recording and works data
    // this only fires when no quansic data is returned
  }

  getMlcDataFromISWC() {
    this.isLoadingISWC = false;
    // function should hit the new endpoint and bring back all works data for a given ISWC
    // this fires any time we know the ISWC
  }

  onClickCheckSongView(iswc) {
    const url = `https://www.ascap.com/repertory#/ace/search/iswc/${iswc}`
    window.open(url, '_blank');
  };

  onClickShowSaveOptions() {
    this.isLoadingLists = true;
    this.listsService.getListsByUserId(this.user.uid).then((data) => {
      if (data) {
        this.userLists = data;
        if (data.length === 0) {
          this.newListName = 'My List';
          this.isNewList = true;
        };
        this.isLoadingLists = false;
        this.showListOverlay = true;
      } else {
        console.log('Error getting user lists'); // TODO: Add friendly error
        this.isLoadingLists = false;
      }
    })
  }

  handleListSelected(list) {
    this.selectedList = list;
  }

  handleEmptyStateListAction(name) {
    this.newListName = name;
    this.isNewList = true;
  }

  onClickSaveToList() {
    if (this.isNewList && this.newListName.length < 0) {
      console.log('List needs a name') // TODO: add error
      return;
    }

    this.isSavingToList = true;
    const trackDataPayload = {
      ...this.trackData,
      savedAt: new Date,
    }

    if (this.isNewList) {
      const newListData = {
        name: this.newListName,
        createdAt: new Date,
        updatedAt: new Date,
        referenceTiles: [this.trackData.albumArtURL]
      }
      this.listsService.createUserList(this.user.uid, newListData).then((data) => {
        if (data != null) {
          this.listsService.addSongToUserList(data, this.user.uid, trackDataPayload).then((data) => {
            if (data != null) {
              this.isSavingToList = false;
              this.showListOverlay = false;
            }
          }).catch((e) => {
            console.log('save song to list error', e); // TODO: ADD ERRORS HERE AND BELOW
          });
        }
      }).catch((e) => {
        console.log('create list error', e);
      });
    } else {
      const newTiles = [...new Set([...this.selectedList.referenceTiles, this.trackData.albumArtURL])];
      const cleanedTiles = newTiles.slice(-4);
      const listUpdate = {
        ...this.selectedList,
        updatedAt: new Date(),
        referenceTiles: cleanedTiles,
      }
      this.listsService.updateUserList(this.user.uid, listUpdate).then(() => {
        this.listsService.addSongToUserList(listUpdate.id, this.user.uid, trackDataPayload).then((data) => {
          if (data != null) {
            this.isSavingToList = false;
            this.showListOverlay = false;
          }
        }).catch((e) => {
          console.log('save song to list error', e); // TODO: ADD ERRORS HERE AND BELOW
        });
      }).catch((e) => {
        console.log('create list error', e);
      })
    }
  }

  onClickCloseSongView() {
    if (this.isListMode) {
      window.history.back();
    } else {
      this.router.navigate(['dashboard']);
    }
  }

  animateInitialContent() {
    setTimeout(() => {
      const trackCardLoadAnimation = anime.timeline({ autoplay: false, loop: false });
      const trackCard = this.el.nativeElement.querySelector('#track-card');

      trackCardLoadAnimation
        .add({
          targets: trackCard,
          scale: [1.05, 1],
          opacity: [0, 1],
          translateY: [-40, 0],
          easing: 'easeOutCubic',
          duration: 900,
        });
      trackCardLoadAnimation.play();
    }, 600);
  };

  copyToClipboard(type: string, value: string): void {
    this.clipboardUtility.copy(value);

    switch (type) {
      case 'iswc':
        this.showCopiedISWC = true;
        break;
      case 'isrc':
        this.showCopiedISRC = true;
        break;
    }

    setTimeout(() => {
      this.zone.run(() => {
        this.showCopiedISRC = false;
        this.showCopiedISWC = false;
      });
    }, 2300);
  }

}
