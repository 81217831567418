import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { SubMonitorService } from 'src/app/services/sub-monitor.service';
import { Title, Meta } from '@angular/platform-browser';
import { DiscoveryService } from 'src/app/services/discovery.service';
import { MicroStateService } from 'src/app/services/micro-state.service';

import { TimeService } from 'src/app/utilities/time.service';
import anime from 'animejs/lib/anime.es';
import { Router } from '@angular/router';
import { SubCheckService } from 'src/app/services/sub-check.service';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  user: any;
  userSubscription: any;
  isLoading: boolean = true;

  showPrimarySearch: boolean = true;

  isLoadingSearchData: boolean = false;
  spotifyToken: any = null;
  searchString: any;
  searchResultData: any;
  isInvalidLink: boolean = false;

  tidalToken: any = null;

  showUtilityBar: boolean = true;

  selectedTrack: any;

  isSpotify: boolean = true;
  showSourceToggle: boolean = false;
  trialDays: any;
  trialHours: any;

  constructor(
    private subMonitor: SubMonitorService,
    private titleService: Title,
    private metaService: Meta,
    private discoveryService: DiscoveryService,
    private microState: MicroStateService,
    private timeService: TimeService,
    private el: ElementRef,
    private router: Router,
    private subCheckService: SubCheckService,
    private analyticsService: AnalyticsService
  ) {
    this.titleService.setTitle('Song Identity - Discover');
    this.metaService.updateTag(
      {
        name: 'description',
        content: 'Song Identity'
      }
    );
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.loadUserData();
    this.getSpotifyToken();
    // this.getTidalToken();

    this.microState.stateData$.subscribe((data) => {
      if (data != null) {
        // this.searchString = data.searchString;
        this.searchResultData = data.searchResultData || null;
        this.trialDays = data.trialDaysRemaining;
        this.trialHours = data.trialHoursRemaining || null;
      }
    });

    if (this.searchResultData) {
      this.showPrimarySearch = false;
      this.handleAnimateResults({ isFirstLoad: true});
    };

    this.subMonitor.getSubscription().subscribe((data) => {
      if (data != null) {
        this.userSubscription = data;
      }
    });

    this.subCheckService.checkSubscription();
  };

  async loadUserData() {
    const auth = await this.getAuthState();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
      }
    });

    this.subMonitor.getSubscription().subscribe((data) => {
      this.userSubscription = data;
      if (this.userSubscription === 'expired') { 
        this.router.navigate(['activate-subscription'])
       };
    });

  };

  async getAuthState() {
    return getAuth();
  };

  getSpotifyToken(): void {
    this.discoveryService.getSpotifyToken().subscribe((token) => {
      if (token) {
        this.spotifyToken = token;
        this.isLoading = false;
      } else {
        console.error('Failed to retrieve Spotify token.'); // add friendly error
      }
    });
  };

  getTidalToken(): void {
    this.discoveryService.getTidalToken().subscribe((token) => {
      if (token) {
        this.tidalToken = token;
      } else {
        console.error('Failed to retrieve TIDAL token.');
      }
    });
  };


  onSearchKeypress(event: KeyboardEvent, options: any): void {
    if (!options.primary) { this.showPrimarySearch = false }
    if (event.key === 'Enter') {
      this.onClickDiscover(options);
    }
  }

  onClickDiscover(options) {
    if (!options.primary) { this.showPrimarySearch = false }

    document.documentElement.scrollTo(0, 0);
    if (this.searchString == null || this.isLoadingSearchData) {
      return;
    }
    this.timeService.startTimer();

    const { isURL, isSpotifyURL, isTrackURL } = this.handleCheckURL(this.searchString);
    if (!isURL) {
      this.isLoadingSearchData = true;
      if (options.primary) { this.handleSearchBarAnimation() }

      if (this.isSpotify) {
        this.handleSpotifySearchQuery();
        this.analyticsService.captureEvent("discovery_query")
      } else {
        this.handleTidalSearchQuery();
      }
     
    } else if (isTrackURL && isSpotifyURL) {
      this.isLoadingSearchData = true;
      if (options.primary) { this.handleSearchBarAnimation() }
      this.handleSpotifyURL()
      this.analyticsService.captureEvent("discovery_url_query")
    } else {
      if (!this.isInvalidLink) { this.isInvalidLink = true };
      setTimeout(() => { this.isInvalidLink = false }, 3000);
    }
  };

  handleSpotifyURL() {
    this.selectedTrack = null;
    this.discoveryService.getSpotifyTrackDataFromUrl(this.searchString, this.spotifyToken).subscribe((data) => {
      if (data) {
        const newData = [];
        const result = this.cleanSpotifySingleData(data);
        newData.push(result);
        this.searchResultData = newData;
        this.microState.setData('searchResultData', newData);
        this.handleSearchComplete();
      } else {
        console.log('No Data Returned') // ADD ERROR
        this.handleSearchComplete();
      }
    })
  };

  handleSpotifySearchQuery() {
    this.selectedTrack = null;
    this.microState.setData('searchString', this.searchString);
    const query = this.concatenateWithPlus(this.searchString);
    this.discoveryService.getSpotifyTrackDataFromSearch(query, this.spotifyToken).subscribe((data) => {
      if (data) {
        const cleanedData = this.cleanSpotifyData(data.tracks.items);
        const bestResults = this.sortByClosestMatch(cleanedData, this.searchString);
        this.searchResultData = bestResults;
        this.microState.setData('searchResultData', bestResults);
        this.handleSearchComplete();
      } else {
        console.log('No Data Returned') // ADD ERROR
        this.handleSearchComplete();
      }
    })
  };

  cleanSpotifyData(tracks) {
    const data = tracks.map((track) => ({
      title: track.name,
      trackSourceId: track.id,
      trackSourceURL: track?.external_urls?.spotify || null,
      isrc: track.external_ids.isrc || 'undefined',
      previewURL: track.preview_url,
      artist: track?.artists[0].name || 'undefined',
      artistImgURL: null,
      popularity: track.popularity || 0,
      album: track?.album?.name || 'undefined',
      albumArtURL: track.album?.images[1].url || null,
      copyright: 'undefined',
    }));
    return data;
  }

  cleanSpotifySingleData(track) {
    const data = {
      title: track.name,
      trackSourceId: track.id,
      isrc: track.external_ids.isrc || 'undefined',
      previewURL: track.preview_url,
      artist: track?.artists[0].name || 'undefined',
      artistImgURL: null,
      popularity: track.popularity || 0,
      album: track?.album?.name || 'undefined',
      albumArtURL: track.album?.images[1].url || null,
      copyright: 'undefined',
    };
    return data;
  }

  async handleTidalSearchQuery() {
    this.selectedTrack = null;
    this.microState.setData('searchString', this.searchString);
    // const query = this.concatenateWithPlus(this.searchString);
    const query = this.searchString;
    this.discoveryService.getTidalDataFromSearch(query, this.tidalToken).subscribe((data) => {
      if (data) {
        const cleanedData = this.cleanTidalData(data.tracks);
        const bestResults = this.sortByClosestMatch(cleanedData, this.searchString);
        this.searchResultData = bestResults;
        this.handleSearchComplete();
      } else {
        console.log('No Data Returned') // ADD ERROR
        this.handleSearchComplete();
      }
    })
  };

  cleanTidalData(tracks) {
    const tracksWithData = tracks.filter((track) => track.resource);
    const data = tracksWithData.map((track) => ({
      title: track.resource.title,
      trackSourceId: track.resource.id,
      isrc: track.resource.isrc || 'undefined',
      previewURL: null,
      artist: track.resource?.artists[0].name || 'undefined',
      artistImgURL: track.resource?.artists[0].picture[5] || null,
      album: track.resource?.album?.title || 'undefined',
      albumArtURL: track.resource?.album?.imageCover[3].url || null,
      copyright: track.resource.copyright || 'undefined',
    }));
    return data;
  }

  onSelectTrack(track) {
    this.selectedTrack = track;
    this.microState.setData('discoveredTrackData', track);
    this.handleAnimateResultsOut();
  };

  onClickCancelBtn() {
    this.showPrimarySearch = true;
    this.searchResultData = null;
    this.searchString = null;
    this.handleAnimateUtilityNavOut();
  }

  // UTILITIES

  handleCheckURL(input: string): { isURL: boolean, isSpotifyURL?: boolean, isTrackURL?: boolean } {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    const isURL = urlRegex.test(input);

    if (isURL) {
      const isSpotifyURL = input.toLowerCase().includes('spotify');
      const isTrackURL = input.toLowerCase().includes('/track/');
      return { isURL, isSpotifyURL, isTrackURL };
    }
    return { isURL };
  }

  concatenateWithPlus(str) {
    const words = str.split(/\s+/);
    const result = words.join('+');
    return result;
  }

  calculateSimilarity(str1: string, str2: string): boolean {
    const similarityThreshold = 0.5; // Adjust the threshold as needed
    const normalizedStr1 = str1.toLowerCase();
    const normalizedStr2 = str2.toLowerCase();

    const similarity = normalizedStr1
      .split('')
      .filter(char => normalizedStr2.includes(char))
      .length / Math.max(normalizedStr1.length, normalizedStr2.length);

    return similarity >= similarityThreshold;
  }

  sortByClosestMatch(objects: any[], originalSearchQuery: string): any[] {
    const sortedArray = [...objects].sort((obj1, obj2) =>
      this.compareByNameSimilarity(obj1, obj2, originalSearchQuery)
    );

    return sortedArray;
  };

  compareByNameSimilarity(obj1: any, obj2: any, originalSearchQuery: string): number {
    let similarity1 = null;
    let similarity2 = null;
    if (originalSearchQuery.includes('by')) {
      similarity1 = this.calculateSimilarity(obj1.title + '' + obj1.artist, originalSearchQuery);
      similarity2 = this.calculateSimilarity(obj2.title + '' + obj2.artist, originalSearchQuery);
      if (similarity1 === similarity2) {
        // If similarities are equal, prioritize the exact match
        return obj1.title + '' + obj1.artist === originalSearchQuery ? -1 : obj2.title + '' + obj2.artist === originalSearchQuery ? 1 : 0;
      } else {
        // Otherwise, sort by similarity in descending order
        return Number(similarity2) - Number(similarity1);
      }
    } else {
      similarity1 = this.calculateSimilarity(obj1.title, originalSearchQuery);
      similarity2 = this.calculateSimilarity(obj2.title, originalSearchQuery);
    }

    if (similarity1 === similarity2) {
      // If similarities are equal, prioritize the exact match
      return obj1.title === originalSearchQuery ? -1 : obj2.title === originalSearchQuery ? 1 : 0;
    } else {
      // Otherwise, sort by similarity in descending order
      return Number(similarity2) - Number(similarity1);
    }
  };

  handleSearchComplete() {
    const elapsedMilliseconds = this.timeService.getElapsedTime();
    const remainingMilliseconds = 800 - elapsedMilliseconds;

    if (elapsedMilliseconds >= 800) {
      // It has been at least 8 seconds
      this.isLoadingSearchData = false;
      this.handleAnimateResults({ isFirstLoad: true });
    } else {
      // Set a timeout for the remaining milliseconds
      setTimeout(() => {
        this.isLoadingSearchData = false;
        this.handleAnimateResults({ isFirstLoad: true });
      }, remainingMilliseconds);
    }
  }

  // COMPLEX ANIMATIONS

  handleSearchBarAnimation() {
    const discoverSearchBarAnimation = anime.timeline({ autoplay: false, loop: false });
    const discoverHeader = this.el.nativeElement.querySelector('#discover-header');
    const discoverBar = this.el.nativeElement.querySelector('#discover-bar');
    const primarySearch = this.el.nativeElement.querySelector('#primary-search-input');
    const searchButton = this.el.nativeElement.querySelector('#search-btn');
    const actionBorder = this.el.nativeElement.querySelector('#action-border');
    discoverBar.style.animation = 'none';
    discoverHeader.style.animation = 'none';

    discoverSearchBarAnimation
      .add({
        targets: actionBorder,
        scale: [1, 2.75],
        direction: 'normal',
        easing: 'easeOutExpo',
        duration: 600,
      })
      .add({
        targets: searchButton,
        borderRadius: ['8px', '24px'],
        scale: [1.125, 0.25],
        opacity: [1, 0],
        duration: 900,
        easing: 'easeInOutCirc',
      }, 100)
      .add({
        targets: actionBorder,
        opacity: [1, 0],
        duration: 1000,
        easing: 'easeInOutQuint',
      }, 200)
      .add({
        targets: primarySearch,
        width: ['90%', '60%'],
        translateY: [0, 12],
        opacity: [1, 0],
        duration: 800,
        easing: 'easeInOutExpo',
      }, 200)
      .add({
        targets: discoverBar,
        opacity: [1, 0,],
        direction: 'normal',
        duration: 1000,
        easing: 'linear',
      }, 600)
      .add({
        targets: discoverHeader,
        opacity: [1, 0,],
        direction: 'normal',
        duration: 1000,
        translateY: [0, 24],
        easing: 'easeInOutExpo',
      }, 100);
    discoverSearchBarAnimation.play();
  };

  handleAnimateResults(options) {
    const resultsAnimation = anime.timeline({ autoplay: false, loop: false });
    if (options.isFirstLoad) {
      this.handleAnimateUtilityButtons();
      this.handleAnimateUtilitySearch();
    }

    setTimeout(() => {
      const resultsWrapper = this.el.nativeElement.querySelector('#spotify-results');

      resultsAnimation
        .add({
          targets: [resultsWrapper, `.result-card`],
          scale: [1.1, 1],
          opacity: [0, 1],
          translateY: [4, 0],
          easing: 'easeOutExpo',
          duration: 900,
          delay: anime.stagger(150), // Stagger the animations by 100 milliseconds
        });
      resultsAnimation.play();
    }, 200);

  };

  handleAnimateResultsOut() {
    const resultsOutAnimation = anime.timeline({ autoplay: false, loop: false });
    this.handleAnimateUtilityNavOut();

    setTimeout(() => {
      const resultsWrapper = this.el.nativeElement.querySelector('#spotify-results');
      document.documentElement.scrollTo(0, 0);

      resultsOutAnimation
        .add({
          targets: [resultsWrapper, `.result-card`],
          scale: [1, 1.01],
          opacity: [1, 0],
          translateY: [0, 56],
          easing: 'easeOutExpo',
          duration: 900,
        })
      resultsOutAnimation.play();
      this.handleGoToTrack();
    }, 200);
  }

  handleGoToTrack() {
    setTimeout(() => {
      this.router.navigate(['song', this.selectedTrack.trackSourceId]);
    }, 400);
  }

  handleAnimateUtilitySearch() {
    const utilitySearchAnimation = anime.timeline({ autoplay: false, loop: false });

    setTimeout(() => {
      const utilitySearch = this.el.nativeElement.querySelector('#utility-search');

      utilitySearchAnimation
      .add({
        targets: utilitySearch,
        scale: [1.1, 1],
        opacity: [0, 1],
        translateY: [0, 64],
        easing: 'easeOutExpo',
        duration: 900,
      });
    utilitySearchAnimation.play();
    }, 200);

  }

  handleAnimateUtilityButtons() {
    const utilityButtonsAnimation = anime.timeline({ autoplay: false, loop: false });

    setTimeout(() => {
      const utilityBtns = this.el.nativeElement.querySelector('#utility-buttons');
      utilityButtonsAnimation
        .add({
          targets: utilityBtns,
          scale: [1.1, 1],
          opacity: [0, 1],
          translateY: [0, 64],
          easing: 'easeOutExpo',
          duration: 900,
        });
      utilityButtonsAnimation.play();
    }, 200);
  }

  handleAnimateUtilityNavOut() {
    const utilityNavOutAnimation = anime.timeline({ autoplay: false, loop: false });

    const utilityBtns = this.el.nativeElement.querySelector('#utility-buttons');
    const utilitySearch = this.el.nativeElement.querySelector('#utility-search');

    utilityNavOutAnimation
      .add({
        targets: utilityBtns,
        opacity: [1, 0],
        translateY: [64, 0],
        translateX: [anime.current, anime.current],
        easing: 'easeOutExpo',
        duration: 900,
      })
      .add({
        targets: utilitySearch,
        opacity: [1, 0],
        translateY: [64, 0],
        easing: 'easeOutExpo',
        duration: 900,
      }, 0);
    utilityNavOutAnimation.play();
  }


}
