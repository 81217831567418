<div class="audio-player" #audioPlayer [ngClass]="{ 'inactive' : !isPlaying }">

    <div class="audio-title" (click)="togglePlay()" *ngIf="!isPlaying && audioUrl">Track Preview</div>
    <div class="audio-title disabled" *ngIf="!isPlaying && !audioUrl">{{unavailableMessage}}</div>

    <div *ngIf="isPlaying" class="eq-container" (click)="togglePlay()" #eqContainer></div>

    <div class="mute-wrapper" (click)="toggleMute()" *ngIf="isPlaying">
        <svg-icon *ngIf="isMuted" [svgStyle]="{ 'height.px':16 }" src="/assets/icons/sound-off.svg"></svg-icon>
        <svg-icon *ngIf="!isMuted" [svgStyle]="{ 'height.px':16 }" src="/assets/icons/sound-on.svg"></svg-icon>
    </div>
    <audio #audioElement (timeupdate)="updateEQ()"></audio>

</div>