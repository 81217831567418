import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgPipesModule } from 'ngx-pipes';

import * as firebase from 'firebase/app';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NavComponent } from './components/nav/nav.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegistrationComponent } from './pages/auth/registration/registration.component';
import { DashboardComponent } from './pages/portal/dashboard/dashboard.component';
import { ResetComponent } from './pages/auth/reset/reset.component';
import { MessagePromptComponent } from './components/data-display/message-prompt/message-prompt.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { OnboardComponent } from './pages/onboard/onboard.component';
import { FullTextSearchPipe } from './pipes/fullTextSearch.pipe';
import { AccountComponent } from './pages/portal/account/account.component';
import { SubscribeComponent } from './pages/portal/subscribe/subscribe.component';
import { SortByPipe } from './pipes/sortByDate.pips';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { TermsComponent } from './pages/public/terms/terms.component';
import { PrivacyComponent } from './pages/public/privacy/privacy.component';
import { BigNodeComponent } from './pages/public/big-node/big-node.component';
import { SavedSongsComponent } from './pages/portal/saved-songs/saved-songs.component';
import { AccountIdvComponent } from './pages/portal/account/account-idv/account-idv.component';
import { LandingAnimationComponent } from './components/animations/landing-animation/landing-animation.component';
import { MicroPlayerComponent } from './components/audio/micro-player/micro-player.component';
import { PopMenuComponent } from './components/menus/pop-menu/pop-menu.component';
import { TypeAheadComponent } from './components/inputs/type-ahead/type-ahead.component';
import { SongViewComponent } from './pages/portal/song-view/song-view.component';
import { SavedSongsListComponent } from './pages/portal/saved-songs-list/saved-songs-list.component';
import { PageLoaderComponent } from './components/loaders/page-loader/page-loader.component';
import { ListenComponent } from './pages/portal/listen/listen.component';
import { ProcessingComponent } from './pages/portal/processing/processing.component';
import { ConfirmActionComponent } from './components/overlays/confirm-action/confirm-action.component';
import { RequestAccessComponent } from './pages/auth/request-access/request-access.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    LoginComponent,
    RegistrationComponent,
    DashboardComponent,
    ResetComponent,
    MessagePromptComponent,
    ResetPasswordComponent,
    OnboardComponent,
    FullTextSearchPipe,
    AccountComponent,
    SubscribeComponent,
    SortByPipe,
    TimeAgoPipe,
    TermsComponent,
    PrivacyComponent,
    BigNodeComponent,
    SavedSongsComponent,
    AccountIdvComponent,
    LandingAnimationComponent,
    MicroPlayerComponent,
    PopMenuComponent,
    TypeAheadComponent,
    SongViewComponent,
    SavedSongsListComponent,
    PageLoaderComponent,
    ListenComponent,
    ProcessingComponent,
    ConfirmActionComponent,
    RequestAccessComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    AngularSvgIconModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() {
    firebase.initializeApp(environment.firebase);
}
}
