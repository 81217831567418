<div class="page-header-buffer"></div>
<page-loader *ngIf="isLoading"></page-loader>
<div class="list-page-wrapper" *ngIf="!isLoading">

    <div id="list-wrapper" class="user-lists-wrapper">

        <section *ngIf="userLists && userLists.length === 0">
            <div class="lists-empty-state">
                <svg-icon [svgStyle]="{ 'height.px':48 }" style="opacity: 0.8;" src="/assets/icons/box-focused-outline.svg"></svg-icon>
                <div class="message">Discover Songs to Create a List</div>
            </div>
        </section>

        <section *ngIf="userLists && userLists.length > 0">

            <div class="lists-utility-row">
                <div class="info">
                    <div class="header">My Lists</div>
                </div>
                <div class="tools">
                    <!--TO DO ADD SORT-->
                    <div id="search-input" class="list-search-wrapper">
                        <input class="ss-input list-search" [(ngModel)]="searchQuery" (ngModelChange)="triggerSearchQuery()" placeholder="Search..."/>
                        <div *ngIf="searchQuery.length > 0" class="list-search-clear-chip" (click)="handleClearSearch()">
                            <svg-icon [svgStyle]="{ 'height.px':8 }" src="/assets/icons/close-solo.svg"></svg-icon>
                        </div>
                    </div>
                </div>
            </div>

            <div class="list-item" *ngFor="let l of userLists | fullTextSearch: searchQuery:'name'" (click)="onClickList(l)" [ngStyle]="{ 'opacity': searchActive ? 1 : 0 }">
                <div class="list-detail-section">
                    <div class="image-tiles" [ngClass]="getTileClass(l.referenceTiles.length)">
                        <div class="tile" *ngFor="let t of l.referenceTiles" [ngClass]="getTileClass(l.referenceTiles.length)">
                          <img [src]="t" alt="Image" />
                        </div>
                      </div>
                    <div class="list-info">
                        <div class="list-name">{{l.name}}</div>
                        <div class="list-updated">Updated: {{l.updatedAt | timeAgoPipe}} ago</div>
                    </div>
                </div>
            </div>

            <div class="lists-empty-state" *ngIf="(userLists | fullTextSearch: searchQuery:'name').length === 0" style="opacity: 1;">
                <svg-icon [svgStyle]="{ 'height.px':48 }" style="opacity: 0.8;" src="/assets/icons/box-focused-outline.svg"></svg-icon>
                <div class="message" style="opacity: 1;">Nothing Matching Your Search</div>
            </div>

        </section>

    </div>
</div>