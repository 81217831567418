import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { MicroStateService } from 'src/app/services/micro-state.service';
import { UserService } from 'src/app/services/user.service';
// import { MshService } from 'src/app/services/msh.service';
// import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  @ViewChild('menu') menu!: ElementRef;
  @ViewChild('header', { static: true }) headerElement!: ElementRef;

  user: any;
  userProfile: any;
  isAuthenticated = false;
  isLoading = true;
  showUserMenu = false;
  menuListener: any;
  showAvatarSection = true;
  showNavLinks: Boolean = true;

  activeLinkSpace: string;
  previousLinkSpace: string = null;
  loadNavHandled: boolean = false;
  showPrimaryNav: boolean = true;

  constructor(
    private router: Router,
    // private mshService: MshService,
    private userService: UserService,
    // private elementRef: ElementRef,
    private renderer: Renderer2,
    private microState: MicroStateService,
  ) {
    this.router.events.subscribe(event => {
      
      if (location.pathname.includes('/activate-subscription')
      ) {
         this.showNavLinks = false;
      } else {
        this.showNavLinks = true;
      }

      if (event instanceof NavigationEnd) {
        this.checkUrlNameSpace();
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnInit(): void {
    // this.handleNavLoadedState();

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        this.isAuthenticated = true;
        this.isLoading = false;
        this.getUserProfile(user.uid);
      } else {
        console.log('no authenticated user')
        this.isLoading = false;
        this.isAuthenticated = false;
        this.user = null;
      }
    });

    this.router.events.subscribe(val => {
      if (location.pathname.includes('/onboard')) {
        this.showAvatarSection = false;
      } else {
        this.showAvatarSection = true;
      }
    });
    
    this.checkUrlNameSpace();
  };

  getUserProfile(id) {
    this.userService.getUserPublicProfileById(id).then((data) => {
      if (data) {
        this.userProfile = data;
        this.microState.setData('userProfile', data);
      }
    });
    this.microState.stateData$.subscribe((data) => {
      if (data != null) {
        this.userProfile = data.userProfile;
      }
    });
  }

  checkUrlNameSpace() {
    const currentUrl = this.router.url.toLowerCase();
    const parts = currentUrl.split('/');
      this.activeLinkSpace = parts.length > 1 ? parts[1] : '';
  }

  onShowUserMenu() {
    if (this.showUserMenu === false) {
      setTimeout(() => {
        this.menuListener = this.renderer.listen('window', 'click', (e: Event) => {
          if (e.target !== this.menu.nativeElement) {
            this.showUserMenu = false;
            this.menuListener();
          }
        });
      }, 10);
      this.showUserMenu = true;
    } else {
      this.showUserMenu = false;
      this.menuListener();
    }
  }

  onClickMenuLink(route: string) {
    this.showUserMenu = false;
    this.menuListener();
    this.router.navigate([route])
  }

  onClickMainMenu(parent) {
    this.previousLinkSpace = this.activeLinkSpace;
    switch (parent) {
      case 'dashboard':
        this.router.navigate(['dashboard']);
        break;
      case 'songs':
        this.router.navigate(['songs']);
        break;

    }
  }

  onClickLogo() {
    if (!this.showNavLinks) {
      return;
    }
    if (this.isAuthenticated && this.showAvatarSection === false) {
      return
    } else if (this.isAuthenticated) {
      this.router.navigate(['/dashboard'])
    } else {
      this.router.navigate(['/'])
    }
  }

  onClickSignOut() {
    this.showUserMenu = false;
    const auth = getAuth();
    signOut(auth);
  }
}
