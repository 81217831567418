import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MicroStateService {
  private stateDataSubject = new BehaviorSubject<any>({});
  stateData$: Observable<any> = this.stateDataSubject.asObservable();
  constructor() {}

  setData(dataKey: string, value: any): void {
    const updatedData = { ...this.stateDataSubject.value, [dataKey]: value };
    this.stateDataSubject.next(updatedData);
  }

  getData(dataKey: string): any {
    return this.stateDataSubject[dataKey];
  }

  clearData(dataKey: string): void {
    const updatedData = { ...this.stateDataSubject.value };
    delete updatedData[dataKey];
    this.stateDataSubject.next(updatedData);
    delete this.stateDataSubject[dataKey];
  }

  getState(): any {
    return this.stateDataSubject;
  }

  clearAllData(): void {
    this.stateDataSubject.next({});
  }
}
