import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  private startTime: number;

  startTimer(): void {
    this.startTime = new Date().getTime();
  }

  getElapsedTime(): number {
    return new Date().getTime() - this.startTime;
  }
}