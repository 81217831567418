import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import anime from 'animejs/lib/anime.es';

@Component({
  selector: 'micro-player',
  templateUrl: './micro-player.component.html',
  styleUrl: './micro-player.component.scss'
})
export class MicroPlayerComponent {
  @ViewChild('audioPlayer') audioPlayerRef!: ElementRef;
  @ViewChild('audioElement') audioElementRef!: ElementRef;
  @ViewChild('eqContainer') eqContainerRef!: ElementRef;

  @Input() audioUrl: string = '';

  isPlaying = false;
  isMuted = false;
  unavailableMessage: string = "No Track Audio"

  togglePlay() {
    this.isPlaying = !this.isPlaying;

    setTimeout(() => {
      const audioElement: HTMLAudioElement = this.audioElementRef.nativeElement;
  
      if (this.isPlaying) {
        this.setupAnimation();
        audioElement.play();
        audioElement.addEventListener('ended', () => {
          this.isPlaying = false;
        });
      } else {
        audioElement.pause();
      }
    }, 200);
  }

  toggleMute() {
    this.isMuted = !this.isMuted;
    const audioElement: HTMLAudioElement = this.audioElementRef.nativeElement;
    audioElement.muted = this.isMuted;
  }

  updateEQ() {
    setTimeout(() => {
      if (this.isPlaying) {
        anime({
          targets: '.eq-bar',
          height: () => anime.random(2, 46) + 'px',
          duration: 300,
          easing: 'linear',
        });
      }
    }, 200);
  }

  private setupAnimation() {

      const eqContainer: HTMLDivElement = this.eqContainerRef.nativeElement;

      for (let i = 0; i < 20; i++) {
        const eqBar = document.createElement('div');
        eqBar.classList.add('eq-bar');
        eqBar.style.width = '2px';
        eqContainer.appendChild(eqBar);
      }

      const audioElement: HTMLAudioElement = this.audioElementRef.nativeElement;
      audioElement.src = this.audioUrl;
  }
}
